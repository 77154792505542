import React from 'react';

// Application dependencies
import {
  useTranslate,
  useTranslateDispatch,
  useTranslateState
} from '../translate';


function LanguageSwitcher() {
  //  const { language } = useTranslateState(); // we get the current language
   const  language  = localStorage.getItem("rcml-lang") // we get the current language
  const i18n = useTranslate(); // we get the utils functions
  const { t, getLanguages } = i18n;
  const dispatch = useTranslateDispatch();


  const onChangeLang = (lang) => {
    dispatch({ type: 'CHANGE_LANGUAGE', language: lang });
  };

  const items = getLanguages().map(key => {
    return key !== language ? (
      <option value={key} key={key}>{t(`LanguageSwitcher.${key}`)}</option>
      // <button
      // className='btn-lang'
      // // style={{fontSize:"10px",border:"0px",color: "black", backgroundColor: "#fff",borderRadius: "98.8px",boxShadow: " 2px 4px 22px 0 rgba(0, 0, 0, 0.16)",padding: "2px 25px 2px 25px",marginRight:"10px"}}
      //   key={key}
      //   onClick={() => {
      //     dispatch({ type: 'CHANGE_LANGUAGE', language: key });
      //   }}
      // >
      //   {t(`LanguageSwitcher.${key}`)}
      // </button>
    ) : (
      <option value={key} key={key} selected> {t(`LanguageSwitcher.${key}`)}</option>
    );
  });

  return (
    // <section>
      <span className="btn-lang-background footer-lang-btn-background">
      <select className='btn-lang' onChange={(e) => onChangeLang(e.target.value)}>
        {items}
      </select>
      </span>
    // </section>
  );
}

export default LanguageSwitcher;