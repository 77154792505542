import { useTimer } from 'react-timer-hook';

function Timer({ expiryTimestamp}) {
    const {
        totalSeconds,
        seconds,
        minutes,
        hours,
        days,
        isRunning,
        start,
        pause,
    } = useTimer({ expiryTimestamp, onExpire: () => console.warn('onExpire called') });


    return (
        <div style={{textAlign: 'center'}}>
            <div className="score_result_title" style={{paddingBottom:'0'}}>
                <p> {days > 0 ? (<>{days} Days</>):(<></>)}  {hours} Hours {minutes} Minutes </p>
            </div>
        </div>
    );
}
export default Timer;