import React, { useState, useEffect } from "react";
import { getWeekRelatedResource } from "../services/ResourceAPIServices";
import { postImageUrl, websiteUrl } from "../UrlPaths";
import { useTranslate } from "../translate";

function WeekPicture() {

  const [count, setCount] = useState(0);
  const [imageUrl, setImageUrl] = useState("");
  const i18n = useTranslate();
  const { t } = i18n;

  useEffect(() => {
    getWeekRelatedResource().then((response) => {
      


        response.records.forEach((resource, index) => {
        
          if(resource.resource_type == 'picture' && resource.path !== ""){
            setCount(1);
            setImageUrl(resource.path)
          }
        });
      
   
    
     
    });
  }, []);

  return (
    <>
      {count > 0 ? (
        <>
         <section className="admag-block">
            <div className="row">
              <div className="col-md-12">
                <h3 className="block-title">
                  <span>{t("pic_of_week")}</span>
                </h3>
              </div>
            </div>
            {/* widget author-widget   widget sidebarWidget */}
            <div className="row">
        
            <div className="col-md-12">
            {/* widget author-widget   widget sidebarWidget */}
            <div className="widget author-widget">
              <center>
                <div>
                
                    <img
                     style={{width:'100%'}}
                      alt=""
                      src={`${postImageUrl}${imageUrl}`}
                      className="avatar"
                    />
                
                </div>
                <div class="author-meta">
                  <div class="author-page-contact"></div>
                </div>
              </center>
            </div>
            </div>

            </div>
          </section>

        </>
      ) : (
        <></>
      )}
    </>
  );
}

export default WeekPicture;
