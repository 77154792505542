import axios from "axios";
import { APIEndpoint } from "../UrlPaths";

export async function getUpcomingMatches() {
  try {
    const response = await axios.post(APIEndpoint + "/getUpcomingMatch");
    return response.data.data;
  } catch (error) {
    return [];
  }
}

export async function getLiveMatches() {
  try {
    const response = await axios.post(APIEndpoint + "/getLiveMatches");
    return response.data.data;
  } catch (error) {
    return [];
  }
}

export async function getAllMatches(data) {
  try {
    const response = await axios.post(APIEndpoint + "/getAllMatch",data);
    return response.data.data;
  } catch (error) {
    return [];
  }
}

export async function getMatchFullScoreData(data) {
  try {
    const response = await axios.post(APIEndpoint + "/getMatchFullScoreData",data);
    return response.data.data;
  } catch (error) {
    return [];
  }
}




