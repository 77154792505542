import React, {useEffect} from "react";
import {Helmet} from "react-helmet";
import Footer from "../components/Footer";
import MainHeader from "../components/MainHeader";
import {useTranslate} from '../translate';
import SideTabs from "../components/SideTabs";
import NewMainHeader from "../components/NewMainHeader";

function PageNotFound() {
    const i18n = useTranslate();
    const {t} = i18n;


    useEffect(() => {
    }, []);
    return (
        <>
            <div id="main" className="header-big">
                <Helmet>
                    <title>{t('page_not_found')}</title>
                </Helmet>
                <NewMainHeader />

                <div className="container main-wrapper">
                    <div className="main-content mag-content clearfix">
                        <div className="row comment-form">
                            <div className="col-md-8">
                                <h3 className="tag-title">Ooops... <span>Error 404 page not found</span></h3>
                                <p>Sorry, but the page you are looking for doesn't exist.</p>
                            </div>
                            <div className="col-md-4" data-stickycolumn>
                                <SideTabs/>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        </>
    );
}

export default PageNotFound;
