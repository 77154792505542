import React, { useState } from "react";
function InningBowlingTable(props) {


  return (<>

    {props.inningBowling.length > 0 ? (<>
      <div style={{ marginBottom: '10px' }}>
      <table className="score-table">
        <thead>
          <tr>
            <th className="sticky-column">BOWLING</th>
            <th></th>
            <th className="text-right">O</th>
            <th className="text-right">M</th>
            <th className="text-right">R</th>
            <th className="text-right">W</th>
            <th className="text-right">ECON</th>
          </tr>
        </thead>
        <tbody>
          {props.inningBowling.map((bowlingData, index) => (<>
            <tr>
              <td className="sticky-column">{bowlingData.bowlName}</td>
              <td></td>
              <td className="text-right">{bowlingData.overs}</td>
              <td className="text-right">{bowlingData.maidens}</td>
              <td className="text-right">{bowlingData.runs}</td>
              <td className="text-right">{bowlingData.wickets}</td>
              <td className="text-right">{parseFloat(bowlingData.economy).toFixed(2)}</td>
            </tr>
          </>))}
        </tbody>
      </table>
      </div>
    </>) : (<></>)}

  </>)
}

export default InningBowlingTable;
