import React, { useState, useEffect } from "react";
import { getWeekRelatedResource } from "../services/ResourceAPIServices";
import { useTranslate } from "../translate";
import WeekVideo from "./WeekVideo";
import WeekPicture from "./WeekPicture";

function WeeklyHighlights() {
  const [count, setCount] = useState(0);

  const i18n = useTranslate();
  const { t } = i18n;

  useEffect(() => {
    getWeekRelatedResource().then((response) => {
      response.records.forEach((resource, index) => {
        if (resource.resource_type == "picture" && resource.path !== "") {
          setCount(1);
        }
        if (resource.resource_type == "video" && resource.path !== "") {
          setCount(1);
        }
      });
    });
  }, []);

  return (
    <>
      {count > 0 ? (
        <>
          <section className="admag-block">
            <div className="row">
              <div className="col-md-12">
                <h3 className="block-title">
                  <span>{t("weekly_highlights")}</span>
                </h3>
              </div>
            </div>
            {/* widget author-widget   widget sidebarWidget */}
            <div className="row">
        
                <WeekPicture/>
                <WeekVideo/>

            </div>
          </section>
        </>
      ) : (
        <></>
      )}
    </>
  );
}

export default WeeklyHighlights;
