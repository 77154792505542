import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {getSideTabsPosts} from "../services/PostAPIServices";
import {postImageUrl} from "../UrlPaths";
import {Time} from "./Time";
import {useTranslate} from '../translate';

function SideTabs() {
    const i18n = useTranslate();
    const {t} = i18n;
    const language = localStorage.getItem("rcml-lang");
    const [latestPosts, setLatestPost] = useState([]);
    const [videoPosts, setVideoPosts] = useState([]);
    const [galleryPosts, setGalleryPosts] = useState([]);
    const [categoryList, setCategoryList] = useState([]);
    const [bottomAd, setBottomAd] = useState("");
    const [bottomAdLink, setBottomAdLink] = useState("");

    useEffect(() => {
        const data = {
            language
        };
        getSideTabsPosts(data).then((response) => {
            setLatestPost(response.latest_other_post);
            setVideoPosts(response.latest_video_posts);
            setGalleryPosts(response.latest_gallery_posts);
            setCategoryList(response.categories_post_counts);
        });
    }, []);

    const truncate = (input) =>
        input?.length > 70 ? `${input.substring(0, 60)}...` : input;

    const renderLatestPost = latestPosts.map((item, index) => (
        <article className="widget-post clearfix widget-side-article">
            <div className="simple-thumb">
                <Link reloadDocument to={`/post/${item.post_id}`}>
                    <img src={`${postImageUrl}${item.main_image_path}`} alt=""/>
                </Link>
            </div>
            <header className="mobile-small-element">
                <h3 className="text-warp-3-line" style={{height:'60px'}}>
                    <Link reloadDocument to={`/post/${item.post_id}`}>{truncate(item.title)}</Link>
                </h3>
                <p className="simple-share">
                    <b> <Link to={`/category/${item.slug}`}>
                        {item.category_name} {" "}
                    </Link></b>
                    <Time time={new Date(item.created_at)}/>
                </p>
            </header>
        </article>
    ));

    const renderVideoPosts = videoPosts.map((item, index) => (
        <article className="widget-post clearfix widget-side-article">
            <div className="simple-thumb">
                <Link reloadDocument to={`/post/${item.post_id}`}>
        <span className="play-button">
            <i className="fa fa-play"></i>
          </span>
                    <img src={`${postImageUrl}${item.main_image_path}`} alt=""/>
                </Link>
            </div>
            <header>
                <h3 className="text-warp-3-line">
                    <Link reloadDocument to={`/post/${item.post_id}`}>{truncate(item.title)}</Link>
                </h3>
                <p className="simple-share">
                    <Time time={new Date(item.created_at)}/>
                </p>
            </header>
        </article>
    ));

    const renderGalleryPosts = galleryPosts.map((item, index) => (
        <div className="widget-post clearfix ">
            <div className="author-thumb">
                <Link reloadDocument to={`/post/${item.post_id}`}>
                    <img src={`${postImageUrl}${item.main_image_path}`} alt=""/>
                </Link>
            </div>
            <div>
                <h3>
                    <Link reloadDocument to={`/post/${item.post_id}`}>{truncate(item.title)}</Link>
                </h3>
                <p className="simple-share">
                    <Time time={new Date(item.created_at)}/>
                </p>
            </div>
        </div>
    ));

    const renderCategoriesList = categoryList.map((item, index) => (
        <li>
            <Link to={`/category/${item.slug}`}>
                {item.category_name} <span className="count">{item.post_count}</span>
            </Link>
        </li>
    ));

    return (
        <aside className="sidebar clearfix">
            <div className="widget tabwidget">
                <ul className="nav nav-tabs" role="tablist" id="widget-tab">
                    <li role="presentation" className="active">
                        <a
                            href="#tab-popular"
                            aria-controls="tab-popular"
                            role="tab"
                            data-toggle="tab"
                        >
                            {t('latest')}
                        </a>
                    </li>
                    <li role="presentation">
                        <a
                            href="#tab-recent"
                            aria-controls="tab-recent"
                            role="tab"
                            data-toggle="tab"
                        >
                            {t('videos')}
                        </a>
                    </li>
                    <li role="presentation">
                        <a
                            href="#tab-comments"
                            aria-controls="tab-comments"
                            role="tab"
                            data-toggle="tab"
                        >
                            {t('galleries')}
                        </a>
                    </li>
                </ul>

                <div className="tab-content">
                    <div role="tabpanel" className="tab-pane active" id="tab-popular">
                        {renderLatestPost}
                    </div>
                    <div role="tabpanel" className="tab-pane" id="tab-recent">
                        {renderVideoPosts}
                    </div>

                    <div role="tabpanel" className="tab-pane" id="tab-comments">
                        {renderGalleryPosts}
                    </div>
                </div>
            </div>


            {/* <div className="widget sidebarWidget subscribewidget">
        <h3 className="block-title">
          <span>Subscribe</span>
        </h3>
        <p>
          The more you tighten your grip, Tarkin, the more star systems will
          slip through your fingers.
        </p>
        <form className="form-inline">
          <div className="input-group">
            <input
              type="text"
              className="form-control"
              placeholder="Enter your email"
            />
            <span className="input-group-btn">
              <button className="btn btn-default" type="button">
                Subscribe
              </button>
            </span>
          </div>
        </form>
      </div> */}
        </aside>
    );
}

export default SideTabs;
