import React, { useState, useEffect } from "react";
function MatchDetailsSection(props) {
  const [tossDetails, setTossDetails] = useState("-");

  const getNumberText = (number) => {
    const lastDigit = number % 10;
    const suffix =
      lastDigit === 1 && number != 11
        ? 'st'
        : lastDigit === 2 && number != 12
          ? 'nd'
          : lastDigit === 3 && number != 13
            ? 'rd'
            : 'th';

    return `${suffix}`;
  };

  useEffect(() => {
    setTossDetails(props.matchData.tossResults)
  }, [props]);


  return (<>
    <div className="widget categorywidget">
      <h3 className="block-title">
        <span>Match Details</span>
      </h3>
      <div>
        <table style={{ width: '100%' }}>
          <tr>
            <td className="text-bold">Series</td>
            <td>{props.series}</td>
          </tr>
          <tr>
            <td className="text-bold">Match</td>
            <td>{props.matchData.matchDescription == undefined || props.matchData.matchDescription == 'undefined' || props.matchData.matchDescription == "" ? (<>{props.match_no}<sup>{getNumberText(props.match_no)}</sup> Match</>) : (<>{props.matchData.matchDescription}</>)}</td>
          </tr>
          <tr>
            <td className="text-bold">Venue</td>
            <td>{props.otherData.venue == undefined || props.otherData.venue == 'undefined' || props.otherData.venue == "" ? (<>{props.venue}</>) : (<>{props.otherData.venue['name']}, {props.otherData.venue['city']}</>)}</td>
          </tr>
          {props.matchData.tossResults != undefined ? (<>
            <tr>
              <td className="text-bold">Toss</td>
              <td>{props.matchData.tossResults['tossWinnerName']}, selected to  {props.matchData.tossResults['decision']} first</td>
            </tr>
          </>) : (<></>)}

          {props.matchData.playersOfTheMatch != undefined ? (<>
           {props.matchData.playersOfTheMatch.length > 0 ? (<>
            <tr>
                <td className="text-bold">Player of Match</td>
                <td>{props.matchData.playersOfTheMatch[0]['fullName']} <small>({props.matchData.playersOfTheMatch[0]['teamName']})</small></td>
              </tr>
           </>):(<></>)}
            
          </>) : (<></>)}
          {props.otherData.umpire1 != undefined && props.otherData.umpire2 != undefined ? (<>
            <tr>
              <td className="text-bold">Umpires</td>
              <td>{props.otherData.umpire1 == undefined || props.otherData.umpire1 == 'undefined' || props.otherData.umpire1 == "" ? (<>-</>) : (<>{props.otherData.umpire1['name']}</>)}<br />
                {props.otherData.umpire2 == undefined || props.otherData.umpire2 == 'undefined' || props.otherData.umpire2 == "" ? (<>-</>) : (<>{props.otherData.umpire2['name']}</>)}
              </td>
            </tr>
          </>) : (<></>)}
          {props.otherData.umpire3 != undefined ? (<>
            <tr>
              <td className="text-bold">TV Umpire</td>
              <td>{props.otherData.umpire3 == undefined || props.otherData.umpire3 == 'undefined' || props.otherData.umpire3 == "" ? (<>-</>) : (<>{props.otherData.umpire3['name']}</>)}</td>
            </tr>
          </>) : (<></>)}
          {props.otherData.referee != undefined ? (<>
            <tr>
              <td className="text-bold">Match Referee</td>
              <td>{props.otherData.referee == undefined || props.otherData.referee == 'undefined' || props.otherData.referee == "" ? (<>-</>) : (<>{props.otherData.referee['name']}</>)}</td>
            </tr>
          </>) : (<></>)}
        </table>
      </div>
    </div>

  </>)

}

export default MatchDetailsSection;
