import React, { useContext,useEffect  } from 'react';

import {BrowserRouter, Routes, Route  } from "react-router-dom";
import { HelmetProvider } from 'react-helmet-async';
import Home from "./pages/Home";
import Post from "./pages/Post";
import Category from "./pages/Category";
import SignUp from "./pages/SignUp";
import Login from "./pages/Login";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import Event from "./pages/Event";
import EventView from "./pages/EventView";
import Test from "./pages/Test";
import { TranslateProvider } from './translate';
import Epaper from './pages/Epaper';
import Search from "./pages/Search";
import PageNotFound from "./pages/PageNotFound";
import Series from "./pages/Series";
import SeriesNews from "./pages/SeriesNews";
import PointsTable from "./pages/PointsTable";
import PostType from './pages/PostType';
import Schedule from './pages/Schedule';
import MatchFullScore from './pages/MatchFullScore';



function App() {
  useEffect(() => {
  
  }, []);
  return (
  
    <TranslateProvider>
    <HelmetProvider>
    <BrowserRouter>
       <Routes>
           <Route path="/" element={<Home/>} />
           <Route path="/test" element={<Test/>} />
           <Route path={"/post/:id"} element={<Post/>} />
           <Route path={"/category/:id"} element={<Category/>} />
           <Route path="/sign-up" element={<SignUp/>} />
           <Route path="/login" element={<Login/>} />
           <Route path="/forgot-password" element={<ForgotPassword/>} />
           <Route path="/password/reset/:token/:mail" element={<ResetPassword />} />
           <Route path="/events" element={<Event/>} />
           <Route path="/event/:event_id" element={<EventView/>} />
           <Route path="/e-paper" element={<Epaper/>} />
           <Route path="/search" element={<Search/>} />
           <Route path={"/series/:slug"} element={<Series/>} />
           <Route path={"/series/news/:id"} element={<SeriesNews/>} />
           <Route path={"/points-table/:slug"} element={<PointsTable/>} />
           <Route path={"/posts/:slug"} element={<PostType/>} />
           <Route path={"/match-schedule/:slug"} element={<Schedule/>} />
           <Route path={"/full-scorecard/:slug/:id"} element={<MatchFullScore/>} />
           <Route path="*" element={<PageNotFound/>} />
      </Routes>
    </BrowserRouter>
    </HelmetProvider>
    </TranslateProvider>


  );
}

export default App;
