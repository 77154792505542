import React from 'react';

// Application dependencies
import {
  useTranslate,
  useTranslateDispatch,
  useTranslateState
} from '../translate';


function NewLanguageSwitcher() {
  //  const { language } = useTranslateState(); // we get the current language
   const  language  = localStorage.getItem("rcml-lang") // we get the current language
  const i18n = useTranslate(); // we get the utils functions
  const { t, getLanguages } = i18n;
  const dispatch = useTranslateDispatch();


  const onChangeLang = (lang) => {
    dispatch({ type: 'CHANGE_LANGUAGE', language: lang });
  };

  const items = getLanguages().map(key => {
    return key !== language ? (
      <button
      className='btn-lang'
        key={key}
        onClick={() => {
          dispatch({ type: 'CHANGE_LANGUAGE', language: key });
        }}
      >
        {t(`LanguageSwitcher.${key}`)}
      </button>
    ) : (
        <button
            className='btn-lang selected'
            key={key}
        >
            {t(`LanguageSwitcher.${key}`)}
        </button>
    );
  });

  return (
    // <section>
      <div className="btn-lang-background">
        {items}
      </div>
    // </section>
  );
}

export default NewLanguageSwitcher;