import axios from "axios";
import { APIEndpoint } from "../UrlPaths";

export async function getSeriesWiseData(data) {
  try {
    const response = await axios.post(APIEndpoint + "/getSeriesWiseData",data);
    return response.data.data;
  } catch (error) {
    return [];
  }
}


export async function getSingleNewsPageContent(data) {
  try {
    const response = await axios.post(APIEndpoint + "/getSingleNewsPageContent",data);
    return response.data.data;
  } catch (error) {
    return [];
  }
}

export async function getPointTableContent(data) {
  try {
    const response = await axios.post(APIEndpoint + "/getPointTableContent",data);
    return response.data.data;
  } catch (error) {
    return [];
  }
}





// export async function testScoreAPI() {
//   try {
//     const response = await axios.get("https://cricbuzz-cricket.p.rapidapi.com/mcenter/v1/91713/hscard", {
//       headers: { 'x-rapidapi-host': `cricbuzz-cricket.p.rapidapi.com`, 'x-rapidapi-key':`a424bb1b88msh9b027d219d5c9b4p12a1bfjsn30e858682ee5` },
//     });
//     return response;
//   } catch (error) {
//     return [];
//   }
// }
