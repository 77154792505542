import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {getHomeSection1Posts} from "../services/PostAPIServices";
import {getAllAdvertisements} from "../services/AdvertisementAPIServices";
import {postImageUrl} from "../UrlPaths";
import Event from "./Event";
import SideTabs from "./SideTabs";
import {Time} from "./Time";
import {useTranslate} from '../translate';
import WeekPicture from "./WeekPicture";
import WeekVideo from "./WeekVideo";
import CategoryList from "./CategoryList";

function HomeComponent1() {
    const i18n = useTranslate();
    const {t} = i18n;
    const language = localStorage.getItem("rcml-lang");
    const [trendingPosts, setTrending] = useState([]);
    const [midLatest, setMidLatest] = useState([]);
    const hashTagStorage = localStorage.getItem("hashTagStorage");
    const [leftAd, setLeftAd] = useState("");
    const [leftAdLink, setLeftAdLink] = useState("");
    const [middleAd, setMiddleAd] = useState("");
    const [middleAdLink, setMiddleAdLink] = useState("");
    const [rightAd, setRightAd] = useState("");
    const [rightAdLink, setRightAdLink] = useState("");

    useEffect(() => {
        const data = {
            language
        };
        getHomeSection1Posts(data).then((response) => {
            setTrending(response.trending_top_5);
            setMidLatest(response.latest_top_3);
        });
        getAllAdvertisements(data).then((response) => {

            response.records.forEach(element => {
                if (element.placement_tag == "top_left_side" && (element.img_url != "" && element.img_url != null)) {
                    setLeftAd(postImageUrl + element.img_url);
                    setLeftAdLink(element.link_url)
                }
                if (element.placement_tag == "top_middle_ad" && (element.img_url != "" && element.img_url != null)) {
                    setMiddleAd(postImageUrl + element.img_url);
                    setMiddleAdLink(element.link_url)
                }


                if (element.placement_tag == "top_right_ad" && (element.img_url != "" && element.img_url != null)) {
                    setRightAd(postImageUrl + element.img_url);
                    setRightAdLink(element.link_url)
                }

            });
        });
    }, []);

    function stripHTML(myString) {
        let el = document.createElement("div");
        el.innerHTML = myString;
        return el.textContent || el.innerText || "";
    }

    const truncate = (input) =>
        input?.length > 100 ? `${input.substring(0, 75)}...` : input;

    const renderTrendingPosts = trendingPosts.map((item, index) => (
        <li className="trending-child">
            <article className="small-article">
                <figure className="feed-image">
                    <Link to={`/post/${item.post_id}`}>
                        <img src={`${postImageUrl}${item.main_image_path}`} alt=""/>
                    </Link>
                </figure>
                <h3 className="text-warp-4-line-home">
                    <Link to={`/post/${item.post_id}`}>{truncate(item.title)} </Link>
                </h3>
                <p className="simple-share act-time">
                    <b><Link to={`/category/${item.slug}`} className="">
                        {item.category_name} {" "}
                    </Link></b>
                    <Time time={new Date(item.created_at)}/>
                </p>
            </article>
        </li>
    ));

    const renderLatestPosts = midLatest.map((post, index) => (
        index == 2 ? (<>
            {middleAdLink != '' ? (<>
                <center>
                    <div className="widget">
                        <a href={middleAdLink} target="_blank">
                            <img src={middleAd} alt=""/>
                        </a>
                    </div>
                </center>
            </>) : (<></>)}

            <article className="news-block big-block">
                <Link to={`/post/${post.post_id}`} className="overlay-link">
                    <figure className="image-overlay">
                        <img src={`${postImageUrl}${post.main_image_path}`} alt=""/>
                    </figure>
                </Link>
                <Link to={`/category/${post.slug}`} className="category">
                    {post.category_name}
                </Link>

                <div className="news-details"
                     style={{background: "-webkit-gradient(linear, 50% 0%, 50%  15%, from(rgba(0, 0, 0, 0)), to(rgba(0, 0, 0, 0.99)))"}}>
                    <h3 className="news-title">
                        <Link to={`/post/${post.post_id}`}>{truncate(post.title)}</Link>
                    </h3>

                    <p><br/> <Time time={new Date(post.created_at)}/></p>

                </div>
            </article>
        </>) : (
            <article className="news-block big-block">
                <Link to={`/post/${post.post_id}`} className="overlay-link">
                    <figure className="image-overlay">
                        <img src={`${postImageUrl}${post.main_image_path}`} alt=""/>
                    </figure>
                </Link>
                <Link to={`/category/${post.slug}`} className="category">
                    {post.category_name}
                </Link>

                <div className="news-details"
                     style={{background: "-webkit-gradient(linear, 50% 0%, 50%  15%, from(rgba(0, 0, 0, 0)), to(rgba(0, 0, 0, 0.99)))"}}>
                    <h3 className="news-title">
                        <Link to={`/post/${post.post_id}`}>{truncate(post.title)}</Link>
                    </h3>
                    {index == 0 ? (
                        <>
                            {" "}
                            <p>{stripHTML(post.short_content)}...
                                <br/>
                                <Time time={new Date(post.created_at)}/>
                            </p>
                        </>
                    ) : (
                        <><p><br/> <Time time={new Date(post.created_at)}/></p></>
                    )}

                    {/* <p className="simple-share">
          <Time time={new Date(post.created_at)} />
        </p> */}
                </div>
            </article>)
    ));

    return (
        <div className="row main-body" data-stickyparent>
            {/*<div className="col-md-7">*/}
                <section className="admag-block">
                    {/*<div className="row">*/}
                        <div className="col-md-2">
                            <div className="news-feed">
                                <h3 className="block-title">
                                    <span>{t('trending')}</span>
                                </h3>
                                <ul className="widget-content trending-parent">{renderTrendingPosts}</ul>
                                <div style={{padding: '0px 0 12px 0'}}><Link to={'posts/trending'} class="view-more">{t('more_trending')}</Link></div>
                                {leftAd != "" ? (<>
                                    <center>
                                        <div className="widget">
                                            <a href={leftAdLink} target="_blank">
                                                <img src={leftAd} alt=""/>
                                            </a>
                                        </div>
                                    </center>
                                </>) : (<></>)}


                            </div>
                        </div>

                        <div className="col-md-5">{renderLatestPosts}</div>

                        <div className="col-md-3 col-sm-7" data-stickycolumn>



                            <SideTabs/>
                            <WeekPicture/>
                            <WeekVideo/>
                        </div>
                        <div className="col-md-2 col-sm-5" data-stickycolumn>
                            {rightAd != '' ? (<>
                                <center>
                                    <div className="widget">
                                        <a href={rightAdLink} target="_blank">
                                            <img src={rightAd} alt=""/>
                                        </a>
                                    </div>
                                </center>
                            </>) : (<></>)}

                            {hashTagStorage == '#mobile' ? (<></>) : (<>   <Event/></>)}
                            <CategoryList/>

                        </div>
                    {/*</div>*/}
                </section>
            {/*</div>*/}


        </div>
    );
}

export default HomeComponent1;
