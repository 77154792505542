import React, { useContext, useEffect } from 'react';
const PageDataContext = React.createContext({});
const PageDataProvider = ({ children }) => {
    const [pageData, setPageData] = React.useState({
      title: 'Default Title',
      description: 'Default Description',
      keywords: 'Default Keywords',
    });
    return (
      <PageDataContext.Provider value={{ pageData, setPageData }}>
        {children}
      </PageDataContext.Provider>
    );
  };

// const usePageMeta = (title, description) =>{
//     const defaultTitle = "app-name";
//     const defaultDesc = "meta description";

//     useEffect(() => {
//         document.title = title || defaultTitle;
//         document.querySelector("meta[name='description']").setAttribute("content", description || defaultDesc);
//     }, [defaultTitle, title, defaultDesc, description]);
// };

function Test() {
  const { pageData, setPageData } = useContext(PageDataContext);

  useEffect(() => {
    document.title = pageData.title;
    const metaDescription = document.querySelector('meta[name="description"]');
    metaDescription.setAttribute('content', pageData.description);
    const metaKeywords = document.querySelector('meta[name="keywords"]');
    metaKeywords.setAttribute('content', pageData.keywords);
  }, [pageData]);
// usePageMeta("Demo Page Title", "Demo meta description");
  return (
    <div>
         
      {/* <h1>{pageData.title}</h1> */}
      {/* <p>{pageData.description}</p> */}
      <button onClick={() => setPageData({
        title: 'New Title',
        description: 'New Description',
        keywords: 'New Keywords',
      })}>Update Data</button>
    </div>
  );
}

export default Test;