import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../components/Footer";
import MainHeader from "../components/MainHeader";
import { loginAPI } from "../services/AuthAPIServices";
import "antd/dist/antd.css";
import Swal from "sweetalert2";
import { useTranslate } from '../translate';
import NewMainHeader from "../components/NewMainHeader";

function Login() {
  const navigate = useNavigate();
  const i18n = useTranslate();
  const { t } = i18n;
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [buttonLoading, setButtonLoading] = useState(false);

  const beforelogin = sessionStorage.getItem('beforeLogin');

  useEffect(() => {}, []);

  const handleSubmitAllData = (e) => {
    e.preventDefault();
    const data = {
      password,
      email,
    };

    setButtonLoading(true);
    loginAPI(data).then((response) => {
      if (response.status_code == 1) {
        Swal.fire("Success", "" + response.message, "success");
        localStorage.setItem("user", response.data.name);
        localStorage.setItem("user_id", response.data.user_id);
        localStorage.setItem("img_path", response.data.path);
        localStorage.setItem("authenticated", true);
        localStorage.setItem("access_token", response.data.access_token);
        navigate(beforelogin == null ? "/" : beforelogin );
        navigate(0);
      } else {
        Swal.fire("warning", " " + response.message, "warning");
      }
      setButtonLoading(false);
    });
  };

  return (
    <>
      <div id="main" class="header-big">
      <Helmet>
      <title>{t('login_page_title')}</title>
      </Helmet>
        <NewMainHeader />

        <div class="container main-wrapper">
          <div class="main-content mag-content clearfix">
            <div id="registerForm" className="registerForm-wrapper clearfix">
              <div id="respond" className="comment-form clearfix">
                <h3
                  className="comment-title text-center"
                >
                  {t('login_button')}
                </h3>

                <form
                  onSubmit={(e) => {
                    handleSubmitAllData(e);
                  }}
                >
                  <div className="row">
                    <div className="col-md-4"> </div>
                    <div className="col-md-4">
                      <label for="form-action-3">
                      {t('email_label')} <span style={{ color: "red" }}>*</span>
                      </label>
                      <div className="form-group">
                        <input
                          type="text"
                          id="email"
                          className="form-control"
                          placeholder="Email"
                          name="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-4"> </div>
                    <div className="col-md-4">
                      <label for="form-action-3">
                      {t('password_label')} <span style={{ color: "red" }}>*</span>
                      </label>
                      <div className="form-group  d-flex justify-content-center">
                        <input
                          type="password"
                          id="password"
                          className="form-control"
                          placeholder="Password"
                          name="password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4"> </div>
                    <div className="col-md-4">
                      <p>
                        <Link to={`/forgot-password`}>
                          <span>  {t('forgot_pwd_label')}</span>
                        </Link>
                      </p>
                    </div>
                  </div>
                  <div className="row text-center">
                    <div className="col-md-4"> </div>
                    <div className="col-md-4">
                      <button
                        name="submit"
                        type="submit"
                        id="submit-button"
                        disabled={buttonLoading}
                        value="Submit"
                        className="btn btn-primary form-control"
                      >
                        {buttonLoading == true ? (
                          <>
                            <i className="ft-refresh-ccw"> </i>
                            <span>{t('submitting')}...</span>
                          </>
                        ) : (
                          <>
                           {t('login_button')}
                          </>
                        )}
                      </button>
                      <br />
                      <label>
                        <Link to={`/sign-up`}>
                          <span> {t('account_create_link')}</span>
                        </Link>
                      </label>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>

      <div
        id="go-top-button"
        class="fa fa-angle-up"
        title="Scroll To Top"
      ></div>
      <div class="mobile-overlay" id="mobile-overlay"></div>
    </>
  );
}
export default Login;
