import React, {useEffect, useState} from "react";
import Flag from 'react-world-flags'
import Timer from "./Timer";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import moment from "moment";
import {postImageUrl} from "../UrlPaths";
import {Link} from "react-router-dom";
import SeriesTimer from "./SeriesTimer";

function SeriesUpcomingScoreCard(props) {


  const getNumberText = (number) => {
    const lastDigit = number % 10;
    const suffix =
        lastDigit === 1 && number != 11
            ? 'st'
            : lastDigit === 2 && number != 12
                ? 'nd'
                : lastDigit === 3 && number != 13
                    ? 'rd'
                    : 'th';

    return `${suffix}`;
  };

  TimeAgo.addLocale(en);
  // Create a new instance
  // const timeAgo = new TimeAgo("en-GB");
  // const inSeconds = new Date(new Date(date).toLocaleString('en', {timeZone: localStorage.getItem("tz")})).getTime();
  // const minutesAgo = timeAgo.format(inSeconds - 60 * 1000);

  // console.log('ddm:',new Date(new Date(date).toLocaleString('en', {timeZone: localStorage.getItem("tz")})));
    let match_date = props.dataList.match_date +'T'+ props.dataList.match_time;
    // const millisecondsDifference = moment(match_date).format() - moment().tz("Europe/London").format();
    // const secondsDifference = millisecondsDifference / 1000;
    // var day_seconds = Math.floor(secondsDifference);

    const time2 = new Date(match_date);
    time2.setSeconds(time2.getSeconds());

    // var hours_seconds = (moment(time, 'hh:mm').format('hh') * 3600) + (moment(time, 'hh:mm').format('mm') * 60);
    // console.log('sss:',new Date(new Date().toLocaleString('en', {timeZone: localStorage.getItem("tz")})))
    // const timeCal = new Date(new Date().toLocaleString('en-GB', {timeZone: 'Europe/London'}))
    // let total = day_seconds;
    // timeCal.setSeconds(timeCal.getSeconds() + total);

    var dateText = moment(props.dataList.match_date).from(new Date());
    var startOfToday = moment().startOf('day');
    var startOfDate = moment(props.dataList.match_date).startOf('day');
    var daysDiff = startOfDate.diff(startOfToday, 'days');
    var days = {
        '0': 'today',
        '-1': 'yesterday',
        '1': 'tomorrow'
    };

    if (Math.abs(daysDiff) <= 1) {
        dateText = days[daysDiff];
    }
    const truncate = (input) =>
        input?.length > 15 ? `${input.substring(0, 12)}...` : input;

  return (<>

      <div className="sidebarWidget">
      <article className="[ card ]">
        <div className="inner">
            <b><p>
            {props.dataList.format == 'IPL' ? (<>
                {props.dataList.match_no.length > 2 ? props.dataList.match_no : (<>{props.dataList.match_no}<sup>{getNumberText(props.dataList.match_no)}</sup> Match </>)}({props.dataList.day_night}) | {moment(props.dataList.match_date).format("MMM D, YYYY")} | {truncate(props.dataList.venue)}
            </>):(<>
                {props.dataList.match_no.length > 2 ? props.dataList.match_no :(<>{props.dataList.match_no}<sup>{getNumberText(props.dataList.match_no)}</sup></>)} {props.dataList.format} | {props.dataList.series} | {truncate(props.dataList.venue)}
            </>)}
            </p></b>
            <div className="scorecard-content">
              <div className="card_column_upcoming">
                  <img src={postImageUrl+props.dataList.team_A_flag_path} width={30} style={{borderRadius:0}}/>
                 <span className="country_score_title "> &nbsp; {props.dataList.format == 'IPL' ? (<>{props.dataList.team_A_full_name}</>):(<>{props.dataList.team_A_full_name}</>)}</span>
              </div>
              <div className="card_column_upcoming_right text-right">
                <span className="country_score_title">
                    {dateText == 'today' || dateText == 'tomorrow' ? dateText.charAt(0).toUpperCase()+ dateText.slice(1): moment(props.dataList.match_date).format('D MMM YY')}
                    {/*{minutesAgo.charAt(0).toUpperCase() + minutesAgo.slice(1)}*/}
                </span>
              </div>
            </div>
          <div className="scorecard-content">
            <div className="card_column_upcoming">
                <img src={postImageUrl+props.dataList.team_B_flag_path} width={30} style={{borderRadius:0}}/>
             <span className="country_score_title"> &nbsp; {props.dataList.format == 'IPL' ? (<>{props.dataList.team_B_full_name}</>):(<>{props.dataList.team_B_full_name}</>)}</span>
            </div>
            <div className="card_column_upcoming_right text-right">
              <span className="country_score_title">{moment(props.dataList.match_time, 'hh:mm').format('h:mm A')}</span>
            </div>
            </div>
          <div  className="scorecard-content">
              {props.dataList.toss != null ?(<>
                  <span className="score_result_title">{props.dataList.toss}</span>
              </>):(<>
                  <span> Match starts in &nbsp;</span><SeriesTimer expiryTimestamp={time2} />
              </>)}
          </div>

        </div>

      </article>
      </div>
  </>)
}

export default SeriesUpcomingScoreCard;
