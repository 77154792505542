import { Link, useNavigate,useLocation } from "react-router-dom";
import React, { useState, useEffect } from "react";
import {websiteUrl } from "../UrlPaths";
import { useTranslate } from '../translate';
import LanguageSwitcher from "./LanguageSwitcher";
import { logoutAPI } from "../services/AuthAPIServices";
import { getSocialMediaLinks } from "../services/CategoryAPIServices";
import Swal from "sweetalert2";


function Footer() {
  const { hash } = useLocation();
  const i18n = useTranslate();
  const { t } = i18n;
  const userName = localStorage.getItem("user");
  const [loggedUser, setLoggedUser] = useState(userName);
  if (localStorage.getItem("hashTagStorage") === null) {
    localStorage.setItem("hashTagStorage",hash)
  }
  const language = localStorage.getItem("rcml-lang");
  const beforelogout = sessionStorage.getItem('beforeLogin');
  const [fbLink, setFbLink] = useState("");
  const [instagramLink, setInstagramLink] = useState("");
  const [youtubeLink, setyoutubeLink] = useState("");
  const [twitterLink, setTwitterLink] = useState("");
  const [hashTag, setHashTag] = useState(localStorage.getItem("hashTagStorage"));
  let navigate2 = useNavigate();

  useEffect(() => {
    
    getSocialMediaLinks().then((response) => {
        setFbLink(response.links.fb_link);
        setInstagramLink(response.links.instagram_link);
        setyoutubeLink(response.links.youtube_link);
        setTwitterLink(response.links.twitter_link);
    });
  }, []);

  const logoutEvent = (e) => {
    Swal.fire({
      title: "Are you sure logout?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        logoutAPI().then((response) => {
          if (response.status_code == "1") {
            localStorage.removeItem("access_token");
            localStorage.setItem("authenticated", false);
            localStorage.removeItem("user");
            localStorage.removeItem("img_path");
            localStorage.removeItem("user_id");
            navigate2(beforelogout == null ? "/" : beforelogout );
            // navigate2("/");
            navigate2(0);
          }
        });
      }
    });
  };
  
  return (
    <footer
      className="footer source-org vcard copyright clearfix"
      id="footer"
      role="contentinfo"
    >
      <div className="footer-main">
        <div className="fixed-main">
          <div className="container">
            <div className="mag-content">
              <div className="row">
              <div className="col-md-8 col-sm-6 clearfix">
              <div className="footer-block clearfix ">
                     
                      <p className="clearfix" >
                        <Link
                          to={`/`}
                          className="logo"
                          title="win365 sports"
                          rel="home"
                        >
                          <img
                              width={280}
                            src={`${websiteUrl}img/new_logo_1.png`}
                            alt="footer-logo"
                          />
                        </Link>
                      </p>
            
                    </div>

              </div>
            
                <div className="col-md-4 col-sm-6 clearfix">
                <div className="widget footer-block clearfix ">
              <div className="footer-top-btn-section">
                  <div className="footer-login-btn-set">
              {hashTag == '#mobile' ? (<>
                </>):(<>
                  {loggedUser == "" || loggedUser == null ? (<>
                      {language == 'english' ?(<>
                    <Link to={`/sign-up`}><button className="sign-login-btn-en">{t('sign_up')}</button></Link>
                    <Link to={`/login`}><button className="new-login-btn-en">{t('login')}</button> </Link>
                     </> ):(<>
                    
                          <Link to={`/sign-up`}><button className="sign-login-btn-sin">{t('sign_up')}</button></Link>
                    <Link to={`/login`}><button className="new-login-btn-sin">{t('login')}</button> </Link>
                     
                     </> )}
                     
                        </>) : (
                    <>
                      <span>Hello, {loggedUser}</span>
                      <span>
                        {" "}
                        |{" "}
                        <button
                          style={{
                            background: "none",
                            border: "none",
                            color: "white",
                          }}
                          type="button"
                          onClick={(e) => {
                            logoutEvent();
                          }}
                        >
                          <i className="fa fa-power-off"></i>
                        </button>
                      </span>
                    </>
                  )} 
                    {/* <LanguageSelector /> */}
               
                </>)}
                  </div>
                  <div>
                      <LanguageSwitcher></LanguageSwitcher>
                  </div>

              </div>
              {/* <div style={{textAlign: 'end', paddingRight: 0}}>
                   
                    </div> */}
              </div>
              </div>
              <div className="col-md-3 col-sm-4 clearfix">
                <div className="widget footer-block clearfix">
                  <ul className="footer-menu">
                    <li><Link reloadDocument to={`/`}>{t('home')}</Link> </li>
                    <li><Link  to={`/category/vollyball`}>{t('vollyball')}</Link> </li>
                    <li><Link  to={`/category/aquatic`}>{t('aquatic')}</Link> </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-3 col-sm-4 clearfix">
              <div className="widget footer-block clearfix">
                  <ul className="footer-menu">
                    <li><Link  to={`/category/football`}>{t('football')}</Link> </li>
                    <li><Link  to={`/category/cricket`}>{t('cricket')}</Link> </li>
                    <li><Link  to={`/category/athletic`}>{t('athletic')}</Link> </li>
                 
                  </ul>
                </div>
              </div>
              <div className="col-md-3 col-sm-4 clearfix">
              <div className="widget footer-block clearfix">
                  <ul className="footer-menu">
                  <li><Link reloadDocument to={`/category/local-sports`}>{t('local_sports')}</Link> </li>
                  <li><Link  to={`/category/international-sports`}>{t('international_sports')}</Link> </li>
                      <li><Link  to={`/category/rugby`}>{t('rugby')}</Link> </li>
                 
                  </ul>
                </div>
              </div>
                  {/*<div className="col-md-12 clearfix"></div>*/}

              <div className="col-md-12 col-sm-12 clearfix" style={{display:"flex"}}>

              <div className="footer-socialmedia-block">
    
              <ul className="social-list clearfix" style={{padding:'0'}}>
           
           <li>
           <a
             href={fbLink}
             data-toggle="tooltip"
             target="_blank"
             data-placement="bottom"
             title="Facebook"
           >
             <img
                 src={`${websiteUrl}icons/fb.png`}
                 alt="facebook icon"
               />
           </a>
           </li>
           <li data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Instagram">
           <a
             href={instagramLink}
             data-toggle="tooltip"
             target="_blank"
             data-placement="bottom"
             title="Instagram"
           >
              <img
                 src={`${websiteUrl}icons/in.png`}
                 alt="instagram icon"
               />
           </a>
           </li>
           <li data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Twitter">
           <a
             href={twitterLink}
             data-toggle="tooltip"
             target="_blank"
             data-placement="bottom"
             title="Twitter"
           >
             <img
                 src={`${websiteUrl}icons/tw.png`}
                 alt="Twitter icon"
               />
           </a>
           </li>
                  <li data-toggle="tooltip" data-placement="bottom" title="" data-original-title="YouTube">
                      <a
                          href={youtubeLink}
                          data-toggle="tooltip"
                          target="_blank"
                          data-placement="bottom"
                          title="YouTube"
                      >
                          <img
                              src={`${websiteUrl}icons/ut.png`}
                              alt="YouTube icon"
                          />
                      </a>
                  </li>

         </ul>
            
              </div>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="footer-bottom clearfix">
        <div className="fixed-main">
          <div className="container">
            <div className="mag-content">
              <div className="row">
                <div className="col-md-12 text-center">
                  <p>{t('copyright')} © 2024 {t('ln_sports')}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
