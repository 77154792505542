import React, { useState, useEffect } from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Link, useParams,useLocation } from "react-router-dom";
import { postImageUrl } from "../UrlPaths";

import Footer from "../components/Footer";
import MainHeader from "../components/MainHeader";
import SideTabs from "../components/SideTabs";
import { Time } from "../components/Time";
import { useTranslate } from '../translate';
import {getPointTableContent, getSeriesWiseData} from "../services/SeriesAPIServices";
import SeriesScoreCard from "../components/SeriesScoreCard";
import SeriesUpcomingScoreCard from "../components/SeriesUpcomingScoreCard";
import {Table} from "antd";
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import NewMainHeader from "../components/NewMainHeader";

function PointsTable() {
  const seriesLocation = useLocation()
  // const { id } = seriesLocation.state;
  const { slug } = useParams();
  const i18n = useTranslate();
  const { t } = i18n;
  const language = localStorage.getItem("rcml-lang");
  const [seriesName, setSeriesName] = useState("");
  const [totalPost, setTotalPost] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  sessionStorage.setItem('beforeLogin',location.pathname);

  useEffect(() => {
    var data = { id:slug };
    setLoading(true);
    getPointTableContent(data).then((response) => {
        console.log('data',response);
      setSeriesName(response.series)
      setTableData(response.teamList);
      setLoading(false);

    });
  }, [slug]);

  const screens = useBreakpoint();
  const { xs, sm, md } = screens;

  const columns = [
    {
      key: "short_name",
      title: "",
      dataIndex:"short_name",
      fixed: 'left',
      responsive: ['xs'],
      render: (record) => {
        return (
            <>
              <div className="flex">
                <b><span>{record}</span></b>
              </div>
            </>
        );
      }
    },

    {
      key: "info",
      title: "",
      responsive: ['md'],
      render: (record) => {
        return (
            <>
              <div className="flex">
                {record.row_type.includes('divider') ? (<>
                <b><span>{record.name_official}</span></b>
                </>):(<>
                  <b><span>
                  <img src={postImageUrl+record.flag_path} width={30} style={{borderRadius:'0px'}} />
                   &nbsp;{record.name_official} </span></b>
                </>)}
                
              </div>
            </>
        );
      }
    },
    {
      key: "played_matches_count",
      title: "M",
      dataIndex:"played_matches_count",
      width: 10,
      // sorter: (a, b) => a.played_matches_count - b.played_matches_count,

    },
    {
      key: "won_count",
      title: "W",
      dataIndex:"won_count",
      width: 10,
      // sorter: (a, b) => a.won_count - b.won_count,

    },
    {
      key: "lost_count",
      title: "L",
      dataIndex:"lost_count",
      width: 10,
      // sorter: (a, b) => a.lost_count - b.lost_count,

    },
    {
      key: "tied_count",
      title: "T",
      dataIndex:"tied_count",
      width: 10,
      // sorter: (a, b) => a.tied_count - b.tied_count,

    },
    {
      key: "abandoned_count",
      title: "N/R",
      dataIndex:"abandoned_count",
      width: 10,
      // sorter: (a, b) => a.abandoned_count - b.abandoned_count,

    },
    {
      key: "points_awarded_count",
      title: "PT",
      dataIndex:"points_awarded_count",
      width: 10,
    },
    {
      key: "net_run_rate",
      title: "NRR",
      dataIndex:"net_run_rate",
      width: 10,
    }
  ].filter((item) => !item.hidden);

  return (
    <>
     <HelmetProvider>
      <div id="main" className="header-big">
      <Helmet>
      <title> {seriesName} Points Table |  {t('ln_sports')}</title>
      </Helmet>
        <NewMainHeader />

        <div className="container main-wrapper">
          <div className="main-content mag-content clearfix">
            <div className="row main-body" data-stickyparent>
              <div className="col-md-12">
                <section className="admag-block">
                  <div className="row">
                    <div className="col-md-1"></div>
                    <div className="col-md-10">
                      <h3 className="block-title">
                        <span> {seriesName} - Points Table</span>
                      </h3>
                      {/*<section className="admag-block">*/}
                      <div className="table-responsive">
                      <Table columns={columns} loading={loading} dataSource={tableData} size="middle"  pagination={false}
                              rowClassName={(record, index) => ((record.row_type==='divider' ? 'p-table-grp' : ''))}
                             scroll={{ x: true }}
                      />
                      </div>
                      {/*</section>*/}
                    </div>
                    <div className="col-md-1"></div>
                  </div>
                </section>
                <div className="row"></div>
              </div>

            </div>
          </div>
        </div>
        <Footer />
      </div>

      <div
        id="go-top-button"
        class="fa fa-angle-up"
        title="Scroll To Top"
      ></div>
      <div class="mobile-overlay" id="mobile-overlay"></div>
      </HelmetProvider>
    </>
  );
}
export default PointsTable;
