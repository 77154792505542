import React, {useEffect, useState} from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {logoutAPI} from "../services/AuthAPIServices";

import {websiteUrl} from "../UrlPaths";
import Swal from "sweetalert2";
import Navbar from './Navbar';
import LanguageSwitcher from "./LanguageSwitcher";
import {useTranslate} from '../translate';
import Search from "./Search";
import {HeaderTimer} from "./HeaderTimer";
import FlagSwitcher from "./FlagSwitcher";
import {TimeZoneProvider} from "../timezone";

function MainHeader() {
    const i18n = useTranslate();
    const {hash} = useLocation();
    const {t} = i18n;

    let navigate2 = useNavigate();
    const userName = localStorage.getItem("user");
    const [loggedUser, setLoggedUser] = useState(userName);
    const [isNavExpanded, setIsNavExpanded] = useState(false);
    const language = localStorage.getItem("rcml-lang");

    const beforelogout = sessionStorage.getItem('beforeLogin');

    if (localStorage.getItem("hashTagStorage") === null) {
        localStorage.setItem("hashTagStorage", hash)
    }
    const [hashTag, setHashTag] = useState(localStorage.getItem("hashTagStorage"));
    const [showMenu, setShowMenu] = useState(window.innerWidth > 1150);
    const [showFlexDisplay, setShowFlexDisplay] = useState(window.innerWidth <= 1150);

    useEffect(() => {

        const handleResize = () => {

            if (window.innerWidth <= 1150) {
                setShowMenu(false);
                setShowFlexDisplay(true)
            } else {
                setShowMenu(true);
                setShowFlexDisplay(false)
            }
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    const logoutEvent = (e) => {
        Swal.fire({
            title: "Are you sure logout?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes",
        }).then((result) => {
            if (result.isConfirmed) {
                logoutAPI().then((response) => {
                    if (response.status_code == "1") {
                        localStorage.removeItem("access_token");
                        localStorage.setItem("authenticated", false);
                        localStorage.removeItem("user");
                        localStorage.removeItem("img_path");
                        localStorage.removeItem("user_id");
                        navigate2(beforelogout == null ? "/" : beforelogout);
                        // navigate2("/");
                        navigate2(0);
                    }
                });
            }
        });
    };

    return (<>
        <header className="header-wrapper clearfix">
            <div style={{position: "relative", zIndex: "-1"}}>
                <div className="header-mid body-color6">
                    <div className="container">
                        <div className="mag-content">
                            <div className={`row ${showFlexDisplay ? "display_flex" : ""}`}>
                                <div className="col-md-4 header-logo">
                                    <div className="widget-new-header">
                                        <Link reloadDocument to={`/`}>
                                            <img
                                                src={`${websiteUrl}img/new_logo_1.png`}

                                                alt="win365 sports"
                                                className="header-main-logo"
                                            />
                                        </Link>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="header-mid-wrapper text-center clearfix">
                                        <div className={`mid-logo-wrapper ${showFlexDisplay ? "display_flex" : ""}`}>
                                            <TimeZoneProvider>
                                                <FlagSwitcher/>
                                                {/*<CustomSelect options={options} value={selectedOption} onChange={handleChange} />*/}
                                                <LanguageSwitcher></LanguageSwitcher>
                                                <HeaderTimer
                                                    classEvent={showFlexDisplay ? "hide_with_margin" : "display_block"}/>
                                            </TimeZoneProvider>

                                        </div>
                                    </div>
                                </div>
                                <div className={` ${showMenu ? "col-md-4" : "hide"}`}>
                                    <div className="widget-new-header">
                                        <div className="row">

                                            <div className="col-md-12 header-login-btn">
                                                {hashTag == '#mobile' ? (<>
                                                </>) : (<>
                                                    <Search/>
                                                    {loggedUser == "" || loggedUser == null ? (<>
                                                        {language == 'english' ? (
                                                            <>
                                                                <Link to={`/login`}>
                                                                    <button
                                                                        className="new-login-btn-en">{t('login')}</button>
                                                                </Link>
                                                                <Link to={`/sign-up`}>
                                                                    <button
                                                                        className="sign-login-btn-en">{t('sign_up')}</button>
                                                                </Link>
                                                            </>
                                                        ) : (
                                                            language == 'tamil' ? (
                                                                <>
                                                                    <Link to={`/login`}>
                                                                        <button
                                                                            className="new-login-btn-tm">{t('login')}</button>
                                                                    </Link>
                                                                    <Link to={`/sign-up`}>
                                                                        <button
                                                                            className="sign-login-btn-tm">{t('sign_up')}</button>
                                                                    </Link>
                                                                </>
                                                            ) : (<>
                                                                <Link to={`/login`}>
                                                                    <button
                                                                        className="new-login-btn-sin">{t('login')}</button>
                                                                </Link>
                                                                <Link to={`/sign-up`}>
                                                                    <button
                                                                        className="sign-login-btn-sin">{t('sign_up')}</button>
                                                                </Link>
                                                            </>))}

                                                    </>) : (
                                                        <>
                                                            <span>Hello, {loggedUser}</span>
                                                            <span>
                            {" "}
                                                                |{" "}
                                                                <button
                                                                    style={{
                                                                        background: "none",
                                                                        border: "none",
                                                                        color: "gray",
                                                                    }}
                                                                    type="button"
                                                                    onClick={(e) => {
                                                                        logoutEvent();
                                                                    }}
                                                                >
                              <i className="fa fa-power-off"></i>
                            </button>
                          </span>
                                                        </>
                                                    )}


                                                </>)}
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Navbar showFlex={showFlexDisplay}/>
            </div>
        </header>
    </>);
}

export default MainHeader;
