import { useTimer } from 'react-timer-hook';

function SeriesTimer({ expiryTimestamp}) {
    const {
        totalSeconds,
        seconds,
        minutes,
        hours,
        days,
        isRunning,
        start,
        pause,
    } = useTimer({ expiryTimestamp, onExpire: () => console.warn('onExpire called') });


    return (
        <div style={{textAlign: 'center'}}>

                <span> {days > 0 ? (<>{days} Days</>):(<></>)} {hours} Hours {minutes} Minutes </span>

        </div>
    );
}
export default SeriesTimer;