import Moment from "react-moment";
import React, {useEffect, useState} from "react";
import 'moment-timezone';
import {getCategoryPosts} from "../services/PostAPIServices";
import {useData} from "../timezone";

export const HeaderTimer = ({classEvent}) => {
    const { sharedData } = useData();
  return (
      <section style={{display: 'flex',alignItems: 'center'}}>

      <span className={`header-timer ${classEvent}`} >
                    &nbsp; <i className="fa-regular fa-clock"></i> <Moment format={'H:mm'} interval={5000} tz={sharedData}></Moment>
        &nbsp; <Moment format={'dddd'} interval={60000} tz={sharedData}></Moment>, <Moment format={'ll'} interval={60000} tz={sharedData}></Moment>
          &nbsp; ({sharedData})
      </span>
      </section>
  );
};
