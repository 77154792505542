import axios from "axios";
import { APIEndpoint } from "../UrlPaths";

export async function getAllAdvertisements(data) {
  try {
    const response = await axios.post(APIEndpoint + "/getAllAdvertisements",data);
    return response.data.data;
  } catch (error) {
    return [];
  }
}



