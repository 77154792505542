import React, { useState, useEffect } from "react";
import {getAllMatches, getLiveMatches, getUpcomingMatches} from "../services/MatchScoreAPIServices";
import CommonScoreCard from "./CommonScoreCard";
import UpcomingScoreCard from "./UpcomingScoreCard";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
      <div
          className={className}
          style={{ ...style,  marginRight: "25px" }}
          onClick={onClick}
      />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
      <div
          className={className}
          style={{ ...style,  marginLeft: "15px",zIndex:"100" }}
          onClick={onClick}
      />
  );
}

function SampleNextArrowMobile(props) {
  const { className, style, onClick } = props;
  return (
      <div
          className={className}
          style={{ ...style, display:'none'}}
          onClick={onClick}
      />
  );
}
function SamplePrevArrowMobile(props) {
  const { className, style, onClick } = props;
  return (
      <div
          className={className}
          style={{ ...style,  display:'none' }}
          onClick={onClick}
      />
  );
}
function ScoreCardSection({pageType,seriesType}) {
  const [liveMatches, setLiveMatches] = useState([]);
  const [upcomingMatches, setUpcomingMatches] = useState([]);
  const [resultsMatches, setResultsMatches] = useState([]);
  const [delayedAbandoned, setDelayedAbandoned] = useState([]);
  const [count, setCount] = useState(0);
  const [seriesCount, setSeriesCount] = useState([]);
  const [isFilterSelected, setIsFilterSelected] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState(0);

  useEffect(() => {

    const fetchData = async () => {
      try {
        let data;
        if(pageType == 'home'){
          data = {series:selectedFilter};
        }else if(pageType == 'serise'){
          data = {series:seriesType};
        }


        getAllMatches(data).then((response) =>{

          setSeriesCount(response.seriesAndCount)
          setUpcomingMatches(response.upcoming);
          setLiveMatches(response.live);
          setResultsMatches(response.results);
          setDelayedAbandoned(response.delayed_abandoned);

          setCount(response.total);
        });
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();

    // Polling interval (every 5 seconds in this example)
    const pollingInterval = setInterval(fetchData, 5000);

    // Cleanup function to clear interval when component unmounts
    return () => clearInterval(pollingInterval);
  }, [selectedFilter,seriesType]);


  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    initialSlide: 0,
    className: "slider variable-width",
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      // {
      //   breakpoint: 1024,
      //   settings: {
      //     slidesToShow: 4,
      //     slidesToScroll: 4,
      //     infinite: false,
      //     dots: false,
      //     variableWidth: false
      //   }
      // },
      // {
      //   breakpoint: 991,
      //   settings: {
      //     slidesToShow: 3,
      //     slidesToScroll: 3,
      //     infinite: false,
      //     dots: false,
      //     // variableWidth: false
      //   }
      // },
      // {
      //   breakpoint: 820,
      //   settings: {
      //     slidesToShow: 2,
      //     slidesToScroll: 2,
      //     infinite: false,
      //     dots: false,
      //     // variableWidth: false
      //   }
      // },
      // {
      //   breakpoint: 600,
      //   settings: {
      //     slidesToShow: 2,
      //     slidesToScroll: 2,
      //     variableWidth: true,
      //     infinite: false,
      //   }
      // },
      {
        breakpoint: 540,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: false,
          nextArrow: <SampleNextArrowMobile />,
          prevArrow: <SamplePrevArrowMobile />,
        }
      }
    ]
  };

  const selectFilters = (selectedValue)=>{
    setSelectedFilter(selectedValue);
  }
  return (<>
      {liveMatches.length === 0 && upcomingMatches.length === 0 && resultsMatches.length === 0  ? (<></>):(
          <div className="score_card_section" style={{paddingTop: `${pageType != 'home'?'10px' :''}`}}>
            <div className="container">
              {pageType=='home'?(<>
                <div className="score_card_div_filter flex-container">
                <span className={`flex-item home-filter-tag ${selectedFilter == 0 ? 'active':''}`} onClick={() => selectFilters(0)} id={0}>All Matches ({count})</span>
                {seriesCount.map((seriesData, index) => (
                    <span className={`flex-item home-filter-tag ${selectedFilter == seriesData.series ? 'active':''}`}  onClick={() => selectFilters(seriesData.series)} id={seriesData.series}>{seriesData.short_code} ({seriesData.total})</span>
                ))}
              </div>
              </>):(<></>)}

              <div className="score_card_div">
              <Slider {...settings}>

                {liveMatches.map((match, index) => (
                    <div style={{ width: 380 }}>
                      <CommonScoreCard key={index} dataList={match} />
                    </div>
                ))}


                {upcomingMatches.map((upcoming, ind) => (
                    <div style={{ width: 380 }}>
                     <UpcomingScoreCard key={ind}  dataList={upcoming}  />
                   </div>
                      ))}

                {resultsMatches.map((match, index) => (
                    <div style={{ width: 380 }}>
                    <CommonScoreCard dataList={match}/>
                    </div>
                ))}

                {delayedAbandoned.map((match, index) => (
                    <div style={{ width: 380 }}>
                      <CommonScoreCard dataList={match}/>
                    </div>
                ))}
              </Slider>
              </div>
            </div>
          </div>
      )}

 </> );
}
export default ScoreCardSection;
