import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { getHomeSection2Posts } from "../services/PostAPIServices";
import { postImageUrl } from "../UrlPaths";
import { Time } from "./Time";
import { useTranslate } from '../translate';

function HomeSection2() {
  const i18n = useTranslate();
  const { t } = i18n;
  const language = localStorage.getItem("rcml-lang");
  const [mainPost, setMainPost] = useState([]);
  const [midPosts, setMidPosts] = useState([]);
  const [lastPosts, setLastPosts] = useState([]);

  useEffect(() => {
    const data = {
      language
    };
    getHomeSection2Posts(data).then((response) => {
      setMainPost(response.entertainmentMainPost);
      setMidPosts(response.entertainmentMidPost);
      setLastPosts(response.entertainmentLastPost);
    });
  }, []);

  const truncate = (input) =>
  input?.length > 100 ? `${input.substring(0, 75)}...` : input;

  const renderMainPosts = mainPost.map((item, index) => (
    <article className="featured-small box-news box-big">
      <Link to={`/post/${item.post_id}`}>
        <img src={`${postImageUrl}${item.main_image_path}`} alt="" />
      </Link>
      <header className="featured-header">
        <Link to={`/category/${item.slug}`} className="category bgcolor2">
          {item.category_name}
        </Link>

        <h2>
          <Link to={`/post/${item.post_id}`}>{truncate(item.title)}</Link>
        </h2>
        <p className="simple-share">
        <Time time={new Date(item.created_at)}/>
         
        </p>
      </header>
    </article>
  ));

  const renderMidPosts = midPosts.map((item, index) => (
    <li className="trending-child">
    <article className="small-article">
      <figure className="feed-image">
      <Link to={`/post/${item.post_id}`}>
          <img src={`${postImageUrl}${item.main_image_path}`} alt="" />
        </Link>
      </figure>
      <h3>
      <Link to={`/post/${item.post_id}`}>{truncate(item.title)}</Link>
      </h3>
      <p className="entertainment-timer">
      <Time time={new Date(item.created_at)}/>
      </p>
    </article>
  </li>
  ));

  const renderLastPosts = lastPosts.map((item, index) => (
    <article className="widget-post clearfix">
    <div className="simple-thumb">
    <Link to={`/post/${item.post_id}`}>
        <img src={`${postImageUrl}${item.main_image_path}`} alt="" />
    </Link>
    </div>
    <header>
      <h3 className="text-warp-3-line">
      <Link to={`/post/${item.post_id}`} style={{lineHeight:'1.4'}}>
      {truncate(item.title)}
        </Link>
      </h3>

     
    </header>
    <p style={{paddingTop: "5px"}} className="entertainment-timer">
      <Time time={new Date(item.created_at)}/>
      </p>
  </article>
  ));

  return (
    <section className="admag-block">
      <div className="row">
        <div className="col-md-12">
          <h3 className="block-title">
            <span>{t('entertainment')}</span>
          </h3>
        </div>
      </div>

      <div className="row">
        <div className="col-md-5">{renderMainPosts}</div>

        <div className="col-md-3">
          <ul className="widget-content trending-parent">
           {renderMidPosts}
          </ul>
        </div>

        <div className="col-md-4">
          <div className="widget reviewwidget">
            {renderLastPosts}
          </div>
        </div>
      </div>
    </section>
  );
}
export default HomeSection2;
