import React, {useEffect, useState} from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {logoutAPI} from "../services/AuthAPIServices";

import {websiteUrl} from "../UrlPaths";
import Swal from "sweetalert2";
import Navbar from './Navbar';
import LanguageSwitcher from "./LanguageSwitcher";
import {useTranslate} from '../translate';
import Search from "./Search";
import {HeaderTimer} from "./HeaderTimer";
import FlagSwitcher from "./FlagSwitcher";
import {TimeZoneProvider} from "../timezone";
import {SocialMediaNew} from "./SocialMediaNew";
import NewLanguageSwitcher from "./NewLanguageSwitcher";
import NewNavbar from "./NewNavbar";

function NewMainHeader() {
    const i18n = useTranslate();
    const {hash} = useLocation();
    const {t} = i18n;

    let navigate2 = useNavigate();
    const userName = localStorage.getItem("user");
    const [loggedUser, setLoggedUser] = useState(userName);
    const [isNavExpanded, setIsNavExpanded] = useState(false);
    const language = localStorage.getItem("rcml-lang");

    const beforelogout = sessionStorage.getItem('beforeLogin');

    if (localStorage.getItem("hashTagStorage") === null) {
        localStorage.setItem("hashTagStorage", hash)
    }
    const [hashTag, setHashTag] = useState(localStorage.getItem("hashTagStorage"));
    const [showMenu, setShowMenu] = useState(window.innerWidth > 1023);
    const [showFlexDisplay, setShowFlexDisplay] = useState(window.innerWidth <= 1023);

    useEffect(() => {

        const handleResize = () => {

            if (window.innerWidth <= 1023) {
                setShowMenu(false);
                setShowFlexDisplay(true)
            } else {
                setShowMenu(true);
                setShowFlexDisplay(false)
            }
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    const logoutEvent = (e) => {
        Swal.fire({
            title: "Are you sure logout?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes",
        }).then((result) => {
            if (result.isConfirmed) {
                logoutAPI().then((response) => {
                    if (response.status_code == "1") {
                        localStorage.removeItem("access_token");
                        localStorage.setItem("authenticated", false);
                        localStorage.removeItem("user");
                        localStorage.removeItem("img_path");
                        localStorage.removeItem("user_id");
                        navigate2(beforelogout == null ? "/" : beforelogout);
                        // navigate2("/");
                        navigate2(0);
                    }
                });
            }
        });
    };

    return (<>
        <header className="header-wrapper clearfix">
            <div style={{ zIndex: "-1"}}>
                <div className="header-mid body-color6">
                    <div className="container">
                        <div className={` ${showFlexDisplay ? "" : "mag-content"}`}>
                            <div className={`${showFlexDisplay ? "hide" : ""}`}>
                                <div className="col-md-2 col-sm-12 col-xs-12 text-center">
                                    {/*<div className="widget-new-header">*/}
                                        <Link reloadDocument to={`/`}>
                                            <img
                                                src={`${websiteUrl}img/new_logo_1.png`}
                                                alt="win365 sports"
                                                className="header-main-logo"
                                            />
                                        </Link>
                                    {/*</div>*/}

                            </div>
                            </div>
                                <div className="col-md-10 col-sm-12 col-xs-12 mobile-top-header-area" >
                                    <div className="new-header-mid">

                                        <div className={` ${showFlexDisplay ? "" : "hide"}`}>
                                            <div className="mobile-logo" style={{display: 'flex'}}>
                                                <Link reloadDocument to={`/`}>
                                                    <img
                                                        src={`${websiteUrl}img/new_logo_1.png`}
                                                        alt="win365 sports"
                                                        className="header-main-logo"
                                                    />
                                                </Link>
                                            </div>

                                        </div>
                                    <div>
                                        <div className="header-time-section">

                                            <TimeZoneProvider>
                                                <FlagSwitcher/>
                                                <HeaderTimer
                                                    classEvent={showFlexDisplay ? "hide_with_margin" : "display_block"}/>
                                            </TimeZoneProvider>

                                        </div>




                                    </div>
                                    <div className={` ${showMenu ? "" : "hide"}`}>
                                        <div className="widget-new-header">
                                            <div className={` ${showFlexDisplay ? "row" : "row row-margin-add"}`}>

                                                <div className="col-md-12 header-login-btn">
                                                    {hashTag == '#mobile' ? (<>
                                                    </>) : (<>
                                                        {loggedUser == "" || loggedUser == null ? (<>
                                                                    <Link to={`/sign-up`}>
                                                                        <button
                                                                            className="sign-login-btn-en">{t('sign_up')}</button>
                                                                    </Link>
                                                                    <Link to={`/login`}>
                                                                        <button
                                                                            className="new-login-btn-en">{t('login')}</button>
                                                                    </Link>
                                                                </>
                                                            ) : (
                                                            <>

                                                                <span className="header-username" >Hello, {loggedUser}</span>
                                                                    <button
                                                                        className="header-logout-btn"
                                                                        type="button"
                                                                        onClick={(e) => {
                                                                            logoutEvent();
                                                                        }}
                                                                    >
                                                                  <i className="fa fa-power-off"></i>
                                                                </button>

                                                            </>
                                                        )}


                                                    </>)}
                                                    <NewLanguageSwitcher></NewLanguageSwitcher>
                                                </div>

                                            </div>
                                        </div>

                                    </div>

                                        <div className={` ${showMenu ? "hide" : ""}`}>
                                            <div className="widget-new-header">
                                                <div className={` ${showFlexDisplay ? "" : "row"}`}>
                                                    <div className= {` ${showFlexDisplay ? "col-md-12 header-login-btn mobile-padding-remove" : "col-md-12 header-login-btn"}`}>
                                                        <NewLanguageSwitcher></NewLanguageSwitcher>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                        <div className={` ${showFlexDisplay ? "" : "row row-margin-add"}`}>
                                    <div className={` ${showFlexDisplay ? "col-md-12 mobile-padding-remove" : "col-md-12"}`}>
                                            <NewNavbar showFlex={showFlexDisplay}/>
                                    </div>

                                    </div>
                                </div>


                        </div>

                    </div>
                </div>

            </div>
        </header>
    </>);
}

export default NewMainHeader;
