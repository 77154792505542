import React, { useState, useEffect, useMemo } from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Link, useParams, useLocation } from "react-router-dom";
import { postImageUrl } from "../UrlPaths";

import Footer from "../components/Footer";
import MainHeader from "../components/MainHeader";
import SideTabs from "../components/SideTabs";
import { Time } from "../components/Time";
import { useTranslate } from '../translate';
import { getSeriesWiseData } from "../services/SeriesAPIServices";
import SeriesScoreCard from "../components/SeriesScoreCard";
import SeriesUpcomingScoreCard from "../components/SeriesUpcomingScoreCard";
import NewMainHeader from "../components/NewMainHeader";
import ReactPaginate from "react-paginate";
import { AiFillLeftCircle, AiFillRightCircle } from "react-icons/ai"; // icons form react-icons
import { IconContext } from "react-icons";
import Pagination from "../components/Pagination/Pagination";
let PageSize = 12;
function Schedule() {
  const seriesLocation = useLocation()
  // const { id } = seriesLocation.state;
  const { slug } = useParams();
  const i18n = useTranslate();
  const { t } = i18n;
  const language = localStorage.getItem("rcml-lang");
  
  const [resultsMatches, setResultsMatches] = useState([]);
  const [upcomingMatches, setUpcomingMatches] = useState([]);
  const [seriesName, setSeriesName] = useState("");
  const [totalPost, setTotalPost] = useState(0);
  const [format, setFormat] = useState("");
  const location = useLocation();
  sessionStorage.setItem('beforeLogin', location.pathname);
  const [resultScorecardFullList, setResultScorecardFullList] = useState(false);
  const [fixturesScorecardFullList, setFixturesScorecardFullList] = useState(false);


  useEffect(() => {
    var data = { id: slug, language: language };
    getSeriesWiseData(data).then((response) => {

 
      setTotalPost(response.posts.news.length)
      setSeriesName(response.posts.series.name);
      setUpcomingMatches(response.matches.upcoming);
      setResultsMatches(response.matches.results);
      setFormat(response.posts.series.short_code);

    });
  }, [slug]);



  const renderUpcomingMatches = upcomingMatches.map((matchItem, ind) => {
    if (ind <= 7) {
      return <SeriesUpcomingScoreCard dataList={matchItem} />
    }
  });

  const renderResultsMatches = resultsMatches.map((item, index) => {
    if (index <= 8) {
      return <SeriesScoreCard dataList={item} pageType={'schedule'} />
    }

  });
  return (
    <>
      <HelmetProvider>
        <div id="main" className="header-big">
          <Helmet>
            <title> {seriesName} Schedules|  {t('ln_sports')}</title>
          </Helmet>
          <NewMainHeader />

          <div className="container main-wrapper">
            <div className="main-content mag-content clearfix">
              <div className="row main-body" data-stickyparent>
                <div className="col-md-12">
                  <section className="admag-block">
                    <div className="row">
                      <div className="col-md-1"></div>
                      <div className="col-md-10">
                        <h3 className="text-center">
                          <strong> {seriesName} Schedule</strong>
                        </h3>
                        <section >
                          <div className="widget tabwidget">
                            <ul className="nav nav-tabs" role="tablist" id="widget-tab" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                              <li role="presentation" >
                                <a
                                  href="#tab-popular"
                                  aria-controls="tab-popular"
                                  role="tab"
                                  data-toggle="tab"
                                >
                                  {t('series_page_results')}
                                </a>
                              </li>
                              <li role="presentation" className="active">
                                <a
                                  href="#tab-recent"
                                  aria-controls="tab-recent"
                                  role="tab"
                                  data-toggle="tab"
                                >
                                  {t('series_page_fixtures')}
                                </a>
                              </li>

                            </ul>

                            <div className="tab-content">
                              <div role="tabpanel" className="tab-pane" id="tab-popular">
                                {resultsMatches.length == 0 ? (<><h4 className="text-muted">{t('no_matches')} </h4></>) : (<>
                                  {renderResultsMatches}
                                  {resultScorecardFullList
                                    ? resultsMatches.map((dataRow, index) => {
                                      if (index > 8) {
                                        return <SeriesScoreCard dataList={dataRow} pageType={'schedule'}/>;
                                      }
                                    })
                                    : null}
                                  {resultsMatches.length > 7 ? (<>
                                    <div className="btn-full-list" onClick={() => setResultScorecardFullList(!resultScorecardFullList)}>{resultScorecardFullList ? "View Less" : "View All Results"}</div>
                                  </>) : (<></>)}


                                </>)}

                              </div>
                              <div role="tabpanel" className="tab-pane active" id="tab-recent">
                                {upcomingMatches.length == 0 ? (<><h4 className="text-muted">{t('no_matches')} </h4></>) : (<>
                                  {renderUpcomingMatches}
                                  {fixturesScorecardFullList
                                    ? upcomingMatches.map((dataRow, index) => {
                                      if (index > 7) {
                                        return <SeriesUpcomingScoreCard dataList={dataRow} />;
                                      }
                                    })
                                    : null}
                                  {upcomingMatches.length > 6 ? (<>
                                    <div className="btn-full-list" onClick={() => setFixturesScorecardFullList(!fixturesScorecardFullList)}>{fixturesScorecardFullList ? "View Less" : "View All Fixtures"}</div>
                                  </>) : (<></>)}
                                </>)}
                              </div>
                            </div>
                          </div>

                        </section>
                      </div>
                      <div className="col-md-1"></div>
                    </div>
                  </section>
                </div>


              </div>
            </div>
          </div>
          <Footer />
        </div>

      </HelmetProvider>
    </>
  );
}
export default Schedule;
