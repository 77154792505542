import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {getSideTabsPosts} from "../services/PostAPIServices";
import {postImageUrl} from "../UrlPaths";
import {Time} from "./Time";
import {useTranslate} from '../translate';

function CategoryList() {
    const i18n = useTranslate();
    const {t} = i18n;
    const language = localStorage.getItem("rcml-lang");
    const [latestPosts, setLatestPost] = useState([]);
    const [videoPosts, setVideoPosts] = useState([]);
    const [galleryPosts, setGalleryPosts] = useState([]);
    const [categoryList, setCategoryList] = useState([]);
    const [bottomAd, setBottomAd] = useState("");
    const [bottomAdLink, setBottomAdLink] = useState("");

    useEffect(() => {
        const data = {
            language
        };
        getSideTabsPosts(data).then((response) => {
            setCategoryList(response.categories_post_counts);
        });
    }, []);

    const truncate = (input) =>
        input?.length > 70 ? `${input.substring(0, 60)}...` : input;


    const renderCategoriesList = categoryList.map((item, index) => (
        <li>
            <Link to={`/category/${item.slug}`}>
                {item.category_name} <span className="count">{item.post_count}</span>
            </Link>
        </li>
    ));

    return (
            <div className="widget categorywidget">
                <h3 className="block-title">
                    <span>{t('categories')}</span>
                </h3>
                <ul>
                    {renderCategoriesList}
                </ul>
                <center>
                    <div className="widget">
                        <a href={bottomAd} target="_blank">
                            <img src={bottomAdLink} alt=""/>
                        </a>
                    </div>
                </center>
            </div>
    );
}

export default CategoryList;
