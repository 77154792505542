import React, { useState, useEffect } from "react";
import { Link, useParams, useLocation } from "react-router-dom";

import {
  getEventDetails,
  getLiveStream,
  getLiveStreamState,
} from "../services/EventAPIServices";
import Footer from "../components/Footer";
import MainHeader from "../components/MainHeader";
import SideTabs from "../components/SideTabs";
import { Time } from "../components/Time";
import ReactPlayer from "react-player";
import HelmetMetaData from "../components/MetaData";
import { postImageUrl } from "../UrlPaths";

function EventView() {
  const { event_id } = useParams();
  const userName = localStorage.getItem("user");
  const [loggedUser, setLoggedUser] = useState(userName);
  const [title, setTitle] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  const [streamState, setStreamState] = useState("");
  const [description, setDescription] = useState("");
  const [start_date, setStartDate] = useState("");
  const [start_time, setStartTime] = useState("");
  const [featuredImage, setFeaturedImage] = useState("");

  const location = useLocation();
  sessionStorage.setItem("beforeLogin", location.pathname);
  useEffect(() => {
    var data = { event_id };
    getEventDetails(data).then((response) => {
      setTitle(response.details[0].title);
      setDescription(response.details[0].description);
      setStartDate(response.details[0].start_date);
      setStartTime(response.details[0].start_time);
      setFeaturedImage(response.details[0].featured_image);
      var stream_id = response.details[0].live_streaming_id;
      getLiveStream(stream_id).then((response) => {
        setVideoUrl(response.data.live_stream.player_hls_playback_url);
      });
      var stID = response.details[0].live_streaming_id;

      getLiveStreamState(stID).then((response) => {
        setStreamState(response.data.live_stream.state);
      });
    });
  }, []);
  function createMarkup(eventContent) {
    return { __html: eventContent };
  }

  return (
    <>
      <div id="main" class="header-big">
        
       <HelmetMetaData title={title+' | LN Sports Network'}
          description={title}
          image={`${postImageUrl}${featuredImage}`}
      ></HelmetMetaData>

        <MainHeader />

        <div class="container main-wrapper">
          <div class="main-content mag-content clearfix">
            <div className="row main-body" data-stickyparent>
              <div className="col-md-8">
                {loggedUser == "" || loggedUser == null ? (
                  <>
                    {" "}
                    <p style={{ color: "#555555" }}>
                      <i>If you want to view; Please login : </i>{" "}
                      <Link to={`/login`}>
                        <button
                          name="submit"
                          type="button"
                          className="btn btn-warning "
                          style={{ borderRadius: "5px" }}
                        >
                          Login To View
                        </button>
                      </Link>
                    </p>
                  </>
                ) : (
                  <section className="admag-block">
                    <div className="row">
                      <div class="col-md-12 news-text-block">
                        <h1 className="post-title">{title}</h1>
                        {streamState == "started" ? (
                          <center>
                            <ReactPlayer
                              className="react-player"
                              url={`${videoUrl}`}
                              width="100%"
                              controls={true}
                            />
                          </center>
                        ) : streamState == "stopped" ? (
                          <h4 style={{ color: "red" }}>Event End...!</h4>
                        ) : (
                          <h4 style={{ color: "red" }}>Not stared yet.</h4>
                        )}
                      </div>
                      <div class="col-md-12 news-text-block">
                        <div
                          className="ck-content"
                          dangerouslySetInnerHTML={createMarkup(description)}
                        />
                        <p style={{ color: "#a9a9a9" }}>
                          <b>Start Date</b>: {start_date} <br />
                          <b>Time</b>: {start_time}
                        </p>
                      </div>
                    </div>
                  </section>
                )}
              </div>
              <div className="col-md-4" data-stickycolumn>
                <SideTabs />
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>

      <div
        id="go-top-button"
        class="fa fa-angle-up"
        title="Scroll To Top"
      ></div>
      <div class="mobile-overlay" id="mobile-overlay"></div>
    </>
  );
}
export default EventView;
