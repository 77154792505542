import React, { useState, useEffect } from "react";
// import { Helmet } from "react-helmet";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Link, useParams } from "react-router-dom";
import { postImageUrl } from "../UrlPaths";

import Footer from "../components/Footer";
import RelatedPost from "../components/RelatedPost";
import SideTabs from "../components/SideTabs";

import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import moment from "moment";
import {
  FacebookShareButton,
  TwitterShareButton,
  PinterestShareButton,
} from "react-share";
import HelmetMetaData from "../components/MetaData";

import $ from "jquery";
import { useTranslate } from "../translate";
import MainHeader from "../components/MainHeader";
import { PostHeader } from "../components/PostHeader";
import {getSingleNewsPageContent} from "../services/SeriesAPIServices";
function SeriesNews() {
  const { id } = useParams();
  const queryParameters = new URLSearchParams(window.location.search);
  const preview = queryParameters.get("preview");

  const i18n = useTranslate();
  const { t } = i18n;
  const language = localStorage.getItem("rcml-lang");
  let root_pathname = window.location.href;

  const [postTitle, setPostTitle] = useState("");
  const [seriesName, setSeriesName] = useState("");
  const [seriesId, setSeriesId] = useState("");
  const [author, setAuthor] = useState("");
  const [postBody, setPostBody] = useState("");
  const [featuredImage, setFeaturedImage] = useState("");
  const [nextPostId, setNextPostId] = useState("");
  const [nextPostTitle, setNextPostTitle] = useState("");
  const [previousPostId, setPreviousPostId] = useState("");
  const [previousPostTitle, setPreviousPostTitle] = useState("");
  const [createdDate, setCreatedDate] = useState(new Date());
  const [authorImage, setAuthorImage] = useState("");
  const [viewsCount, setViewsCount] = useState("");
  const [short_content, setShortContent] = useState("");
  const [seriesSlug, setSeriesSlug] = useState("");
  const [role_name, setUserRole] = useState("");

  useEffect(() => {

    var data = { id ,language};
    // document.querySelector("[name='description']").remove();
    getSingleNewsPageContent(data).then((response) => {
      setPostTitle(response.post_content.title);
      setSeriesName(response.seriesName);
      setSeriesId(response.post_content.series_id);
      setAuthor(response.post_content.author);
      setPostBody(response.post_content.post_content);
      setFeaturedImage(response.post_content.main_image_path);
      setCreatedDate(response.post_content.created_at);
      setAuthorImage(response.post_content.profile_img_path);
      setViewsCount(response.post_content.read_count);
      setNextPostId(response.nextPost.id);
      setNextPostTitle(response.nextPost.title);
      setPreviousPostId(response.previousPost.id);
      setPreviousPostTitle(response.previousPost.title);
      setShortContent(response.post_content.short_content);
      setSeriesSlug(response.post_content.slug);
      setUserRole(response.post_content.role_name);
    });

  }, []);
  function createMarkup() {
    return { __html: postBody };
  }

  function calculateTime(time) {
    TimeAgo.addLocale(en);

    const timeAgo = new TimeAgo("en-US");
    const inSeconds = new Date(time).getTime();
    const minutesAgo = timeAgo.format(inSeconds - 60 * 1000);

    return minutesAgo;
  }

  function getDate(time) {
    var date = moment(time).format("MMMM DD,YYYY");

    return date;
  }

  function stripHTML(myString) {
    let el = document.createElement("div");
    el.innerHTML = myString;
    return el.textContent || el.innerText || "";
  }

  return (
      <div id="main" className="header-style2">
      <HelmetMetaData
        title={postTitle + " | "+t('ln_sports')}
        description={stripHTML(short_content) + " ..."}
        image={`${postImageUrl}${featuredImage}`}
      ></HelmetMetaData>
      {/* <MainHeader /> */}
      <PostHeader/>
        <div className="parallax-wrapper2 parallax-box">
          <img src={`${postImageUrl}${featuredImage}`}  alt="" />
          <header className="post-page-header">
            <Link
                to={`/series/${seriesSlug}`}
                state={{ id:seriesId }}
                className="category bgcolor3"
            >
              {seriesName}
            </Link>

            <h1 className="post-title">
              {postTitle}
            </h1>

          </header>
        </div>

      <div className="container">

        <div className="main-content-2">
          <div className="row blog-content" data-stickyparent>

            <div className="col-md-8" data-stickycolumn>
              <article className="post-wrapper clearfix">
                <div className="post-content clearfix">
                  <p className="simple-share">
                    <span className="article-date">
                      <img
                        alt=""
                        src={`${postImageUrl}${authorImage}`}
                        height="32"
                        width="32"
                        loading="lazy"
                        decoding="async"
                      />
                      <span>&nbsp;Published &nbsp;
                      <i className="fa-regular fa-clock"></i>{" "}
                      {calculateTime(new Date(createdDate))} on{" "}
                      {getDate(new Date(createdDate))}</span>
                    </span>
                    <span>
                      <i className="fa fa-eye"></i> {viewsCount} views
                    </span>
                    <br />
                    <span style={{marginTop:'10px'}}>
                      By{" "}
                      <a className="no-hover">
                        <b>{author}</b>
                      </a>
                    </span>
                  </p>
                  <div
                    className="ck-content"
                    dangerouslySetInnerHTML={createMarkup()}
                  />
                    
                </div>

                <footer className="post-meta">
                  <div className="share-wrapper clearfix">
                    <div className="share-buttons">
                      <FacebookShareButton
                        url={root_pathname}
                        image={`${postImageUrl}${featuredImage}`}
                        quote={postTitle}
                        description={stripHTML(short_content)}
                        className="Demo__some-network__share-button"
                      >
                        <a className="social-share facebook">
                          <i className="fab fa-facebook-f"></i>
                          <span className="alt-text">Share</span>
                        </a>
                      </FacebookShareButton>
                      <TwitterShareButton
                        url={root_pathname}
                        title={postTitle}
                        hashtag={"#" + seriesName}
                      >
                        <a className="social-share twitter">
                          <i className="fab fa-x-twitter"></i>
                          <span className="alt-text">Tweet</span>
                        </a>
                      </TwitterShareButton>

                      <PinterestShareButton
                        url={root_pathname}
                        media={`${postImageUrl}${featuredImage}`}
                        description={stripHTML(short_content) + " ..."}
                      >
                        <a className="social-share pinterest">
                          <i className="fab fa-pinterest"></i>
                        </a>
                      </PinterestShareButton>
                    </div>
                  </div>

                  <div className="row">
                    <div className="post-nav-wrapper clearfix">
                      <div className="col-md-6 omega">
                        <div className="previous-post">
                          <div className="post-nav-label">
                            <i className="fa fa-angle-left"></i>
                            &nbsp; {t("dont_miss_label")}
                          </div>
                          <Link
                            reloadDocument
                            to={`/series/news/${previousPostId}`}
                            className="post-nav-title"
                          >
                            {previousPostTitle}
                          </Link>
                        </div>
                      </div>

                      <div className="col-md-6 alpha">
                        <div className="next-post">
                          <div className="post-nav-label">
                            {t("up_next_label")} &nbsp;
                            <i className="fa fa-angle-right"></i>
                          </div>
                          <Link
                            reloadDocument
                            to={`/series/news/${nextPostId}`}
                            className="post-nav-title"
                          >
                            {nextPostTitle}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </footer>
              </article>

            </div>
            <div className="col-md-4" data-stickycolumn>
              <SideTabs />
            </div>
          </div>
        </div>

        <div className="mag-content clearfix">
          <div className="row">
            <div className="col-md-12">
              <div className="ad728-wrapper"></div>
            </div>
          </div>

        </div>
      </div>


      <Footer />
      <div
        id="go-top-button"
        className="fa fa-angle-up"
        title="Scroll To Top"
      ></div>
      <div className="mobile-overlay" id="mobile-overlay"></div>
    </div>

  );
}
export default SeriesNews;
