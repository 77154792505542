import React, {useEffect, useState} from "react";
import {NavLink} from "react-router-dom";
import {getMenuCategories} from "../services/CategoryAPIServices";
import {useTranslate, useTranslateState} from "../translate";
import "./Navbar.css";

function NavigationMenu({postCategory, onclick}) {
    const i18n = useTranslate();
    const {t} = i18n;
    const {lang} = useTranslateState(); // we get the current language
    const language = localStorage.getItem("rcml-lang");
    const [menus, setMenu] = useState([]);
    var path = window.location.pathname;

    useEffect(() => {
        const data = {
            language,
        };
        getMenuCategories(data).then((response) => {
            if (language == "sinhala") {
                setMenu(response.sin_categories);
            } else if (language == "tamil") {
                setMenu(response.tm_categories);
            } else {
                setMenu(response.eng_categories);
            }
        });
    }, []);

    const renderMenu = menus.map((item, index) => (
        <>
            {language == "english" || language == "sinhala" ? (
                <>
                    {path == "/category/" + item.slug ? (
                        <>
                            <li className="dropdown nav__item active">
                                <NavLink

                                    to={`/category/${item.slug}`}
                                    className="dropdown nav__link active capitalize"
                                    role="button"
                                    aria-expanded="false"
                                    onClick={onclick}
                                >
                                    {item.category_name}
                                </NavLink>
                            </li>

                            {/*<Link*/}
                            {/*  to={`/category/${item.slug}`}*/}
                            {/*  className="dropdown items active"*/}
                            {/*  role="button"*/}
                            {/*  aria-expanded="false"*/}
                            {/*>*/}
                            {/*   <li className="dropdown active"> {item.category_name}</li>*/}
                            {/*</Link>*/}

                        </>
                    ) : postCategory == item.slug ? (
                        <>

                            <li className="nav__item active">
                                <NavLink
                                    onClick={onclick}
                                    to={`/category/${item.slug}`}
                                    className="nav__link capitalize"
                                >
                                    {item.category_name}
                                </NavLink>
                            </li>

                        </>
                    ) : (
                        <>

                            <li className="nav__item">
                                <NavLink
                                    onClick={onclick}
                                    to={`/category/${item.slug}`}
                                    className="nav__link capitalize"
                                >
                                    {item.category_name}
                                </NavLink>
                            </li>

                        </>
                    )}
                </>

            ) : path == "/category/" + item.slug ? (
                <>
                    <li className="nav__item active">
                        <NavLink
                            onClick={onclick}
                            to={`/category/${item.slug}`}
                            className="nav__link capitalize"
                        >
                            {item.category_name}
                        </NavLink>
                    </li>
                </>
            ) : postCategory == item.slug ? (
                <>

                    <li className="nav__item active">
                        <NavLink
                            onClick={onclick}
                            to={`/category/${item.slug}`}
                            className="nav__link capitalize"
                        >
                            {item.category_name}
                        </NavLink>
                    </li>
                </>
            ) : (
                <>

                    <li className="nav__item">
                        <NavLink
                            onClick={onclick}
                            to={`/category/${item.slug}`}
                            role="button"
                            aria-expanded="false"
                            className="nav__link active capitalize"

                        >
                            {item.category_name}
                        </NavLink>
                    </li>

                </>
            )}
        </>
    ));

    return (
        <>
            {language == "english" || language == "sinhala" ? (
                <>
                    {path == "/" ? (
                        <>
                            <li className="nav__item active" style={{borderRadius: '12px 0px 0 12px'}}>
                                <NavLink
                                    onClick={onclick}
                                    to={`/`}
                                    role="button"
                                    aria-expanded="false"
                                    className="nav__link capitalize"

                                >
                                    {t("home")}
                                </NavLink>
                            </li>
                        </>
                    ) : (
                        <>
                            <li className="nav__item" style={{borderRadius: '12px 0px 0 12px'}}>
                                <NavLink
                                    onClick={onclick}
                                    to={`/`}
                                    className="nav__link capitalize"
                                >
                                    {t("home")}
                                </NavLink>
                            </li>
                        </>
                    )}
                </>
            ) : path == "/" ? (
                <>
                    <li className="nav__item active" style={{borderRadius: '12px 0px 0 12px'}}>
                        <NavLink
                            reloadDocument
                            to={`/`}
                            className="nav__link capitalize"
                        >
                            {t("home")}
                        </NavLink>
                    </li>

                </>
            ) : (
                <>
                    <li className="nav__item" style={{borderRadius: '12px 0px 0 12px'}}>
                        <NavLink
                            onClick={onclick}
                            to={`/`}
                            className="nav__link capitalize"
                        >
                            {t("home")}
                        </NavLink>
                    </li>
                </>
            )}

            {renderMenu}
        </>
    );
}

export default NavigationMenu;
