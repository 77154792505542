import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";

export const Time = ({ time }) => {
  TimeAgo.addLocale(en);
  // Create a new instance
  const timeAgo = new TimeAgo("en-US");
  const inSeconds = new Date(time).getTime();
  const minutesAgo = timeAgo.format(inSeconds - 60 * 1000);

  return (
    <span className="article-date" style={{color:'#a9a9a9',float: "right"}}>
      <i className="fa-regular fa-clock"></i> {minutesAgo}
    </span>
  );
};
