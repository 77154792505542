import React, { useState } from "react";
function InningNotBattingPlayers(props) {

  return (<>
  {props.inningNotBattingPlayerslist.length > 0 ? (<>
    <div style={{marginBottom:'6px'}}>
      <span> <span className="text-bold">Did not bat :&nbsp;</span>
        {props.inningNotBattingPlayerslist.map((item, index) => (<>
          {item.batName}{item.isCaptain ? (<>(c)</>) : item.isKeeper ? (<><span>†</span></>) : ('')}{index==props.inningNotBattingPlayerslist.length-1 ? (<></>):(<>,&nbsp;</>)} 
        </>))}
      </span>
    </div>
  </>):(<></>)}
    
  </>)
}

export default InningNotBattingPlayers;
