import React, { useState, useEffect } from "react";
function InningBattingTable(props) {
  const [formatted_extra_array, setFormattedExtraArray] = useState([]);

  useEffect(() => {
  
    const arrayFromJson = Object.entries(props.inningExtras);
    const filteredData = arrayFromJson.filter(item => !(item[1] == 0 || item[0] == 'total'));
    setFormattedExtraArray(filteredData);

   }, [props]);
  
  const renderInningBatting = props.inningBattingData.map((item, index) => (<>
    {item.outDesc != "" ? (<>
      <tr>
        <td className="text-bold sticky-column">{item.batName} {item.isCaptain ? (<>(c)</>) : ''} {item.isKeeper ? (<><span>†</span></>) : ''}</td>
        <td>{item.outDesc}</td>
        <td className="text-right">{item.runs}</td>
        <td className="text-right">{item.balls}</td>
        <td className="text-right">{item.fours}</td>
        <td className="text-right">{item.sixes}</td>
        <td className="text-right">{parseFloat(item.strikeRate).toFixed(2)}</td>
      </tr>
    </>) : (<></>)}

  </>));

  

  return (<>
  {props.inningBattingData.length > 0 ? (<>
  <div  className="table-container" style={{marginBottom:'6px'}}>
    <table className="score-table">
      <thead>
        <tr>
          <th className="sticky-column text-left">BATTING</th>
          <th></th>
          <th className="text-right">R</th>
          <th className="text-right">B</th>
          <th className="text-right">4s</th>
          <th className="text-right">6s</th>
          <th className="text-right">SR</th>
        </tr>
      </thead>
      <tbody>
        {renderInningBatting}
        <tr>
          <td className="sticky-column"><span>Extras</span></td>
          <td>
            
            (
            {formatted_extra_array.map((item, index) =>(<>
                {item[0] == 'noBalls' ?(<>nb {props.inningExtras.noBalls}{formatted_extra_array.length == index+1 ? '':','}</>):(<> </>)}
                {item[0] == 'legByes' ?(<>lb {props.inningExtras.legByes}{formatted_extra_array.length == index+1 ? '':','}</>):(<> </>)}
                {item[0] == 'wides' ?(<>w {props.inningExtras.wides}{formatted_extra_array.length == index+1 ? '':','}</>):(<> </>)}
                {item[0] == 'byes' ?(<>b {props.inningExtras.byes}{formatted_extra_array.length == index+1 ? '':','}</>):(<> </>)}
                {item[0] == 'penalty' ?(<>p {props.inningExtras.penalty}{formatted_extra_array.length == index+1 ? '':','}</>):(<> </>)}
            </>)
            )}
            )</td>
          <td className="text-right">{props.inningExtras.total}</td>
          <td colSpan={4}></td>
        </tr>
      </tbody>
      <tfoot >
      <tr>
             <td className="sticky-column text-bold"><span>Total</span></td>
             <td className="text-bold">{props.inningScore.overs} Ov (RR:{parseFloat(props.inningScore.runRate).toFixed(2)})</td>
             <td className="text-bold text-right">{props.inningScore.runs}/{props.inningScore.wickets}</td>
             <td colSpan={4}></td>
           </tr>
        </tfoot>
          
    </table>
    </div>
  </>):(<></>)}

  </>)
}

export default InningBattingTable;
