import React, {createContext, useContext, useState} from 'react';

const TimeZoneContext = createContext();

export const TimeZoneProvider = ({ children }) => {
    const [sharedData, setSharedData] = useState(localStorage.getItem("tz"));

    const updateData = newData => {
        setSharedData(newData);
    };

    return (
        <TimeZoneContext.Provider value={{  sharedData, updateData }}>
            {children}
        </TimeZoneContext.Provider>
    );
};

export const useData = () => {
    return useContext(TimeZoneContext);
};
