import React, { useState } from "react";
function InningFallWickets(props) {


  return (<>
    {props.inningFallWickets.length > 0 ? (<>
      <div className="wicket-container">
        <div className="inning-wickets-header">Fall of wickets</div>
        <div lassName="inning-wickets-body">
          {props.inningFallWickets.map((wicketData, index) => (<>
            <div className="inning_card_column">
              <span>  {wicketData.wktNbr} - {wicketData.wktRuns} ({wicketData.batName}, {Number.isInteger(wicketData.wktOver) ? (<>{wicketData.wktOver-1}.6</>):(<>{wicketData.wktOver}</>)} ov)</span>
            </div>
          </>))}
        </div>
      </div>
    </>) : (<></>)}

  </>)
}

export default InningFallWickets;
