import React, { useState, useEffect } from "react";
import { getSocialMediaLinks } from "../services/CategoryAPIServices";
import { websiteUrl } from "../UrlPaths";

export const SocialMedia = () => {
  const [fbLink, setFbLink] = useState("");
  const [instagramLink, setInstagramLink] = useState("");
  const [youtubeLink, setyoutubeLink] = useState("");

  useEffect(() => {
    
    getSocialMediaLinks().then((response) => {
        setFbLink(response.links.fb_link);
        setInstagramLink(response.links.instagram_link);
        setyoutubeLink(response.links.youtube_link);
    
    });
  }, []);



  return (
    <ul className="social-list clearfix">
           
    <li>
    <a
      href={fbLink}
      data-toggle="tooltip"
      target="_blank"
      data-placement="bottom"
      title="Facebook"
    >
      <img
          className="new-social-icons"
          src={`${websiteUrl}icons/facebook_55x55.svg`}
          alt="facebook icon"
        />
    </a>
    </li>
    <li data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Instagram">
    <a
      href={instagramLink}
      data-toggle="tooltip"
      target="_blank"
      data-placement="bottom"
      title="Instagram"
    >
       <img
      className="new-social-icons"
          src={`${websiteUrl}icons/instagram_55x55.svg`}
          alt="instagram icon"
        />
    </a>
    </li>
    <li data-toggle="tooltip" data-placement="bottom" title="" data-original-title="YouTube">
    <a
      href={youtubeLink}
      data-toggle="tooltip"
      target="_blank"
      data-placement="bottom"
      title="YouTube"
    >
      <img
      className="new-social-icons"
          src={`${websiteUrl}icons/youtube_55x55.svg`}
          alt="youtube icon"
        />
    </a>
    </li>

  </ul>
  );
};
