import axios from "axios";
import { APIEndpoint } from "../UrlPaths";
const token = localStorage.getItem("access_token");

export async function OTPsend(data) {
  try {
    const response = await axios.post(APIEndpoint + "/OTPsend",data);
    return response.data;
  } catch (error) {
    return [];
  }
}

export async function storeCustomer(data) {
  try {
    const response = await axios.post(APIEndpoint + "/storeCustomer",data);
    return response.data.data;
  } catch (error) {
    return [];
  }
}

export async function loginAPI(data) {
  try {
    const response = await axios.post(
     APIEndpoint+'/login', data,
      { headers: { "Content-Type": "application/json"} });

    return response.data;
  } catch (error) {
   
    return error;
  }
}


export async function logoutAPI(streamID) {
  try {

    const  response  = await axios({
      method: 'post',
      url: APIEndpoint+'/logout',
      headers: {'Authorization': `Bearer ${token}`}
  });

    return response.data;
  } catch (error) {
    return error;
  }
}

export async function resetMailSendingAPI(data) {
  try {
    const response = await axios.post(
     APIEndpoint+'/customerPasswordResetMail', data,
      { headers: { "Content-Type": "application/json"} });

    return response.data;
  } catch (error) {
   
    return error;
  }
}

export async function resetPasswordAPI(data) {
  try {
    const response = await axios.post(
     APIEndpoint+'/customerPasswordReset', data,
      { headers: { "Content-Type": "application/json"} });

    return response.data;
  } catch (error) {
   
    return error;
  }
}
