import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { getLatestEvents} from "../services/EventAPIServices";
import { postImageUrl,websiteUrl } from "../UrlPaths";
import { useTranslate } from '../translate';
function Event() {
  const i18n = useTranslate();
  const { t } = i18n;
  const [eventLatest, setEventLatest] = useState([]);
  const [count, setCount] = useState(0);
    
    
  useEffect(() => {
    getLatestEvents().then((response) => {
      setEventLatest(response.records);
      console.log(response.records.length);
      setCount(response.records.length)
    });
  }, []);

    const renderLatestEvent = eventLatest.map((event, index) => (
      <>
       <div className="news-feed">
      <h3 className="text-center event-title-head" > {t("events_title")}</h3>
        <div class="widget author-widget" style={{border:'0px', padding: "0px 0px 0px 0px"}}>
           <center>
        <div>
        <Link to={`/events`}>
            <img alt="" src={`${postImageUrl}${event.featured_image}`} class="avatar" style={{width:'100%'}}/>
          </Link>
        </div>
        <div class="author-meta">
          <h3 class="author-title">
          <Link to={`/events`} >{event.title}</Link>
          </h3>
          <p class="author-position">{event.start_date} {event.start_time}</p>
          {/* <p class="author-bio">TimeZone: {event.time_zone}</p> */}

          <div class="author-page-contact">
           
            <Link to={`/events`} >
              <button
                name="submit"
                type="submit"
                id="submit-button"
                value="Submit"
                className="btn btn-primary btn-more-event"
              >
                {t("btn_more_events")}
              </button>
            </Link>
           

          </div>
        </div>
        </center>
      </div>
      </div>
      </> ));


  return (<>
  {count > 0 ? (renderLatestEvent):(<>
  {/*<h3 className="text-center event-title-head" ><span className="event-section-title"> {t("events_title")}</span></h3>*/}
    <div className="widget author-widget" style={{border:'0px',padding: "0px 0px 0px 0px"}}>
    <center>
          <div>
          <Link to={`/events`}>
              <img alt=""  src={`${websiteUrl}img/default_event_image.png`} class="avatar" style={{width:'100%'}} />
            </Link>
          </div>
          <div className="author-meta">

            <div className="author-page-contact">
             
              <Link to={`/events`} >
                <button
                  name="submit"
                  type="submit"
                  id="submit-button"
                  value="Submit"
                  className="btn btn-primary btn-more-event"
                >
                 {t("btn_more_events")}
                </button>
              </Link>
             

            </div>
          </div>
          </center>
        </div>
  </>)}
    
    </>
  );
}

export default Event;
