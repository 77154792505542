import React, { useState, useEffect } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Link } from "react-router-dom";
import { websiteUrl, postImageUrl } from "../UrlPaths";

import { getAllActivePapers } from "../services/EpaperAPIServices";
import Footer from "../components/Footer";
import MainHeader from "../components/MainHeader";
import SideTabs from "../components/SideTabs";
import { useTranslate } from "../translate";

function Epaper() {
  const i18n = useTranslate();
  const { t } = i18n;
  const language = localStorage.getItem("rcml-lang");
  const [top_papers, setTopEPapers] = useState([]);
  const [other_papers, setOtherList] = useState([]);


  useEffect(() => {
    var data = { language };
    getAllActivePapers(data).then((response) => {
      setTopEPapers(response.top_papers);
      setOtherList(response.other_papers);
    });
  }, []);

  const renderOtherpaperList = other_papers.map((item, index) => (
    <>
     <div className="col-md-3">
     <article className="e-paper-block">
     <a href={`${websiteUrl}ePaper.html?lang=${language}&_id=${item.id}&link=${postImageUrl}${item.pdf}&date=${item.volume_no} - ${item.paper_date}`}
            target="_blank" className="overlay-link">
        <figure className="e-paper-overlay" >
          <img src={`${postImageUrl}${item.thumbnail_img_path}`} alt="" />
        </figure>
      </a>
      <Link  className="e-paper-category">
      E-Paper
      </Link>
      <div className="e-paper-details">
      
        <a href={`${websiteUrl}ePaper.html?lang=${language}&_id=${item.id}&link=${postImageUrl}${item.pdf}&date=${item.volume_no} - ${item.paper_date}`}
            target="_blank" className="overlay-link">
          <h3 className="e-paper-title">Volume {item.volume_no}
        </h3>
        </a>
         <span className="e-paper-date">{item.date}</span>
    
      </div>
      
    </article>
      {/* <article>
      <h3>
        <a
          className="e-blogs"
          href={`${websiteUrl}ePaper.html?lang=${language}&link=${postImageUrl}${item.pdf}&date=${item.volume_no} - ${item.paper_date}`}
          target="_blank"
        >
          {" "}
          {item.volume_no} - {item.paper_date}
        </a>
      </h3>
      </article> */}
      </div>
    </>
  ));

  const renderTopPapers = top_papers.map((item, index) => (
    <>
    <div className="col-md-3">
     
    <article className="e-paper-block">
 
    <a href={`${websiteUrl}ePaper.html?lang=${language}&_id=${item.id}&link=${postImageUrl}${item.pdf}&date=${item.volume_no} - ${item.paper_date}`}
            target="_blank" className="overlay-link">
        <figure className="e-paper-overlay" >
          <img src={`${postImageUrl}${item.thumbnail_img_path}`} alt=""  />
        </figure>
      </a>
      <Link  className="e-paper-category">
      E-Paper
      </Link>
      <div className="e-paper-details">
      
        <a href={`${websiteUrl}ePaper.html?lang=${language}&_id=${item.id}&link=${postImageUrl}${item.pdf}&date=${item.volume_no} - ${item.paper_date}`}
            target="_blank" className="overlay-link">
          <h3 className="e-paper-title">Volume {item.volume_no}
        </h3>
        </a>
         <span className="e-paper-date">{item.date}</span>
    
      </div>

     
    </article>
      {/* <article>
        <h3>
          <a
            className="e-blogs"
            href={`${websiteUrl}ePaper.html?lang=${language}&link=${postImageUrl}${item.pdf}&date=${item.volume_no} - ${item.paper_date}`}
            target="_blank"
          >
            {" "}
            {item.volume_no} - {item.paper_date}
          </a>
        </h3>
      </article> */}
      </div>
    </>
  ));

  return (
    <>
      <HelmetProvider>
        <div id="main" className="header-big">
          <Helmet>
            <title>
              {" "}
              {t("epaper_page_header")} | {t("ln_sports")}
            </title>
          </Helmet>
          <MainHeader />

          <div className="container main-wrapper">
            <div className="main-content mag-content clearfix">
              <div className="row main-body" data-stickyparent>
                <div className="col-md-8">
                  <h3 class="page-subtitle"> {t("epaper_page_header")}</h3>
                  <section className="admag-block">
                    <div className="row">
                      <div className="col-md-12">
                        <h3>{t("latest_paper_label")} :</h3>
                        </div>
                        {renderTopPapers}
                                          
                    </div>
                  </section>
                  <div className="row">
                    <div className="col-md-12 news-text-block">
                      <h3> {t("other_paper_label")} :</h3>
                      </div>
                      {renderOtherpaperList}
                   
                  </div>
                </div>

                <div className="col-md-4" data-stickycolumn>
                  <SideTabs />
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>

        <div
          id="go-top-button"
          class="fa fa-angle-up"
          title="Scroll To Top"
        ></div>
        <div class="mobile-overlay" id="mobile-overlay"></div>
      </HelmetProvider>
    </>
  );
}
export default Epaper;
