import React, { useState, useEffect } from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Footer from "../components/Footer";
import HomeSection1 from "../components/HomeSection1";
import HomeSection2 from "../components/HomeSection2";
import HomeSection3 from "../components/HomeSection3";
import HomeSection4 from "../components/HomeSection4";
import HomeSection5 from "../components/HomeSection5";
import HomeSection6 from "../components/HomeSection6";
import MainHeader from "../components/MainHeader";
import MetaData from "../components/MetaData";
import MobileMenu from "../components/MobileMenu";
import WeeklyHighlights from '../components/WeeklyHighlights';
import { postImageUrl } from "../UrlPaths";
import { getAllAdvertisements } from "../services/AdvertisementAPIServices";
import CommonScoreCard from "../components/CommonScoreCard";
import { getLiveMatches, getUpcomingMatches } from "../services/MatchScoreAPIServices";
import UpcomingScoreCard from "../components/UpcomingScoreCard";
import ScoreCardSection from "../components/ScoreCardSection";
import NewMainHeader from "../components/NewMainHeader";

function Home() {
  const language = localStorage.getItem("rcml-lang");
  const [entertainmentBottomAd, setEnterBottomAd] = useState("");
  const [entertainmentBottomAdLink, setEnterBottomAdLink] = useState("");
  const [pageBottomAd, setPageBottomAd] = useState("");
  const [pageBottomAdLink, setPageBottomAdLink] = useState("");
  const [liveMatches, setLiveMatches] = useState([]);
  const [upcomingMatches, setUpcomingMatches] = useState([]);

  useEffect(() => {
    const data = {
      language
    };
    getAllAdvertisements(data).then((response) => {

      response.records.forEach(element => {
        if (element.placement_tag == "entertainment_section_ad" && (element.img_url != "" && element.img_url != null)) {
          setEnterBottomAd(postImageUrl + element.img_url);
          setEnterBottomAdLink(element.link_url)
        }
        if (element.placement_tag == "bottom_ad" && (element.img_url != "" && element.img_url != null)) {
          setPageBottomAd(postImageUrl + element.img_url);
          setPageBottomAdLink(element.link_url)
        }

      });
    });

    // getLiveMatches().then((response) => {
    //   setLiveMatches(response)
    // });
    //
    // getUpcomingMatches().then((response) => {
    //   setUpcomingMatches(response);
    // });


  }, []);
  return (
    <>
      <div id="main" className="header-big">
        <Helmet>
          <title>Home | Win365 Sports </title>
          <meta name="keywords" content="Win365 Sports" />
          <meta
            name="description"
            content="" />
          <meta name="author" content="Archnix" />
        </Helmet>
        <NewMainHeader />

        <div className=" main-wrapper">
          <div className="main-content">
              <ScoreCardSection pageType="home"/>

            <div className="container">
              <div className=" mag-content clearfix" >
                <HomeSection1 />
                {/* <WeeklyHighlights/> */}
                <HomeSection2 />
                {entertainmentBottomAd != "" ? (<>
                  <div className="mag-content clearfix">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="ad728-wrapper">
                          <a href={entertainmentBottomAd} target="_blank">
                            <img src={entertainmentBottomAdLink} alt="" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div></>) : (<></>)}
                <HomeSection3 />
                <HomeSection4 />
                <HomeSection5 />
                <HomeSection6 />

                {pageBottomAd != "" ? (<>
                  <div className="mag-content clearfix">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="ad728-wrapper">
                          <a href={pageBottomAd} target="_blank">
                            <img src={pageBottomAdLink} alt="" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </>) : (<></>)}
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
export default Home;
