import axios from "axios";
import { APIEndpoint } from "../UrlPaths";

export async function getWeekRelatedResource() {
  try {
    const response = await axios.get(APIEndpoint + "/getWeekRelatedResource");
    return response.data.data;
  } catch (error) {
    return [];
  }
}



