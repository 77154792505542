import React, { useState, useEffect,useMemo } from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Link, useParams, useLocation } from "react-router-dom";
import { postImageUrl } from "../UrlPaths";

import { getCategoryPosts } from "../services/PostAPIServices";
import Footer from "../components/Footer";
import MainHeader from "../components/MainHeader";
import SideTabs from "../components/SideTabs";
import { Time } from "../components/Time";
import { useTranslate } from '../translate';
import NewMainHeader from "../components/NewMainHeader";
import ScoreCardSection from "../components/ScoreCardSection";
import Pagination from "../components/Pagination/Pagination";

function Category() {
  const { id } = useParams();
  const i18n = useTranslate();
  const { t } = i18n;
  const language = localStorage.getItem("rcml-lang");
  const [mainPost, setMainPost] = useState([]);
  const [sidePosts, setSidePosts] = useState([]);
  const [lastPosts, setLastPosts] = useState([]);
  const [categoryName, setCategoryName] = useState("");
  const [totalPost, setTotalPost] = useState(0);
  const [seriesId, setSeriesId] = useState(0);
  const location = useLocation();
  sessionStorage.setItem('beforeLogin', location.pathname);
  let PageSize = 10;

  useEffect(() => {
    var data = { id, language };
    getCategoryPosts(data).then((response) => {
      setTotalPost(response.mainPosts?.length + response.sidePosts?.length + response.otherPosts?.length)
      setMainPost(response.mainPosts);
      setSidePosts(response.sidePosts);
      setLastPosts(response.otherPosts);
      setCategoryName(response.categoryName);
      setSeriesId(response.seriesId)
      console.log('response.seriesId',response.seriesId);
    });
  }, [id, categoryName]);
  function stripHTML(myString) {
    let el = document.createElement("div");
    el.innerHTML = myString;
    return el.textContent || el.innerText || "";
  }

  const [currentPage, setCurrentPage] = useState(1);

  const currentNewsData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return lastPosts.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, lastPosts]);

  const truncate = (input) =>
    input?.length > 60 ? `${input.substring(0, 55)}...` : input;

  const renderLeftSidePosts = sidePosts.map((item, index) => (
    <li className="trending-child">
      <article className="small-article">
        <figure className="feed-image">
          <Link to={`/post/${item.id}`}>
            <img src={`${postImageUrl}${item.main_image_path}`} alt="" />
          </Link>
        </figure>
        <h3>
          <Link to={`/post/${item.id}`}>{item.title} </Link>
        </h3>
        <p>
          <br />
          <Time time={new Date(item.created_at)} />
        </p>
      </article>
    </li>
  ));

  const renderLatestPosts = mainPost.map((post, index) => (
    <article className="news-block">
      <Link to={`/post/${post.id}`} className="overlay-link">
        <figure className="image-overlay" style={{ margin: '0' }} s>
          <img src={`${postImageUrl}${post.main_image_path}`} alt="" />
        </figure>
      </Link>

      <div className="news-details" >
        <h3 className="news-title">
          <Link to={`/post/${post.id}`}>{post.title}</Link>
        </h3>
        {index == 0 ? (
          <>
            {" "}
            <p>{stripHTML(post.short_content)}...</p>
          </>
        ) : (
          <></>
        )}

        <p><br />
          <Time time={new Date(post.created_at)} />
        </p>
      </div>
    </article>
  ));

  const renderLastPosts = lastPosts.map((item, index) => (
    <div className="col-md-6 col-sm-6">
      <article className="news-block article-main flexible">
        <Link reloadDocument to={`/post/${item.id}`} className="overlay-link">
          <figure className="image-overlay">
            <img src={`${postImageUrl}${item.main_image_path}`} alt="" />
          </figure>
        </Link>

        <header className="news-details" style={{ background: 'white', overflow: "hidden" }}>

          <h4 className="heading-text-warp"><b>
            <Link
              reloadDocument
              to={`/post/${item.id}`}
              className="e-blogs"
            >
              {truncate(item.title)}
            </Link>
          </b> </h4>
          <p className="text-warp" style={{ color: '#a9a9a9' }}> {stripHTML(item.short_content)}...
          </p>
          <p><Time time={new Date(item.created_at)} /></p>
          {/* <p className="simple-share" >
            <br />
          </p> */}
        </header>
      </article>
    </div>
  ));
  return (
    <>
      <HelmetProvider>
        <div id="main" className="header-big">
          <Helmet>
            <title> {categoryName} |  {t('ln_sports')}</title>
          </Helmet>
          <NewMainHeader />

          <div className="main-wrapper">
            <div className="main-content">
              {seriesId != "0" ? (<>
                <ScoreCardSection pageType="serise" seriesType={seriesId} />
              </>) : (<></>)}

              <div className="container">
                <div className=" mag-content clearfix">
                  <div className="row main-body" data-stickyparent>
                    <div className="col-md-8">
                      <section className="admag-block">
                        <div className="row">
                          <div className="col-md-4">
                            <div className="news-feed">
                              <h3 className="block-title">
                                <span>{categoryName}</span>
                              </h3>
                              <ul className="widget-content trending-parent">
                                {renderLeftSidePosts}
                              </ul>
                            </div>
                          </div>

                          <div className="col-md-8">
                            {totalPost == 0 ? (<><h4 className="text-muted">{t('no_post')} </h4></>) : (<></>)}
                            {renderLatestPosts}</div>
                        </div>
                      </section>
                      <div className="row">
                        {lastPosts == 0 &&  totalPost != 0 ? (<><div className="col-md-6 col-sm-6"><h4 className="text-muted">{t('no_more_post')} </h4></div></>) : (<>
                          {currentNewsData.map((item) => {
                            return (<>
                              <div className="col-md-6 col-sm-6">
                                <article className="news-block article-main flexible">
                                  <Link reloadDocument to={`/post/${item.id}`} className="overlay-link">
                                    <figure className="image-overlay">
                                      <img src={`${postImageUrl}${item.main_image_path}`} alt="" />
                                    </figure>
                                  </Link>

                                  <header className="news-details" style={{ background: 'white', overflow: "hidden" }}>

                                    <h4 className="heading-text-warp"><b>
                                      <Link
                                        reloadDocument
                                        to={`/post/${item.id}`}
                                        className="e-blogs"
                                      >
                                        {truncate(item.title)}
                                      </Link>
                                    </b> </h4>
                                    <p className="text-warp" style={{ color: '#a9a9a9' }}> {stripHTML(item.short_content)}...
                                    </p>
                                    <p><Time time={new Date(item.created_at)} /></p>
                                    
                                  </header>
                                </article>
                              </div>
                            </>)
                          })}
                          <div className="col-md-12 col-sm-12 col-xs-12" >
                             
                             <Pagination
                               className="pagination-bar"
                               currentPage={currentPage}
                               totalCount={lastPosts.length}
                               pageSize={PageSize}
                               onPageChange={page => setCurrentPage(page)}
                             />
                         
                           </div>
                        </>)}
                      </div>
                    </div>

                    <div className="col-md-4" data-stickycolumn>
                      <SideTabs />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>

      </HelmetProvider>
    </>
  );
}
export default Category;
