import axios from "axios";
import { APIEndpoint,wowza_url,wowzaKey } from "../UrlPaths";



export async function getLatestEvents() {
  try {
    const response = await axios.post(APIEndpoint + "/getLatestEvents");
    return response.data.data;
  } catch (error) {
    return [];
  }
}


export async function getAllActiveEvents() {
  try {
    const response = await axios.post(APIEndpoint + "/getAllActiveEvents");
    return response.data.data;
  } catch (error) {
    return [];
  }
}

export async function getEventDetails(data) {
  try {
    const response = await axios.post(APIEndpoint + "/eventDetails",data);
    return response.data.data;
  } catch (error) {
    return [];
  }
}

export async function getLiveStream(streamID) {
  try {

    const  response  = await axios({
      method: 'get',
      url: wowza_url+"/"+streamID,
      headers: {'Authorization': `Bearer `+wowzaKey+``, 'Content-Type': 'application/json'}
  });

    return response;
  } catch (error) {
    return error;
  }
}
export async function getLiveStreamState(streamID) {
  try {

    const  response  = await axios({
      method: 'get',
      url: wowza_url+"/v1.9/live_streams/"+streamID+"/state",
      headers: {'Authorization': `Bearer ${wowzaKey}`, 'Content-Type': 'application/json'}
  });

    return response;
  } catch (error) {
    return error;
  }
}
