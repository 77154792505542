import React, { useState } from "react";
function PlayerOfMatch(props) {

  return (<>
  {props.playerOfMatch.length > 0 ? (<>
    <div className="widget" style={{padding:'5px',border: '1px solid #1890ff',borderRadius:'8px'}}>
      <h5 className="text-center text-bold"><span>PLAYER OF THE MATCH</span></h5>
      <div style={{padding:'5px'}}>
      <h6>
      {props.playerOfMatch.map((player, index) => (<>
     
        {index == 0 ? (<> <span><b>{player.name}</b></span>, {player.team} <br/> <span style={{lineHeight:'20px'}}>{player.marks}</span></>):(<><span>,&nbsp; {player.marks}</span></>)}
      </>))}
      </h6></div> 
      </div>
    </>) : (<></>)}
  </>)

}

export default PlayerOfMatch;
