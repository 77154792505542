import React, { useState, useEffect, useMemo } from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Link, useParams, useLocation } from "react-router-dom";
import { postImageUrl } from "../UrlPaths";

import Footer from "../components/Footer";
import MainHeader from "../components/MainHeader";
import SideTabs from "../components/SideTabs";
import { Time } from "../components/Time";
import { useTranslate } from '../translate';
import { getSeriesWiseData } from "../services/SeriesAPIServices";
import SeriesScoreCard from "../components/SeriesScoreCard";
import SeriesUpcomingScoreCard from "../components/SeriesUpcomingScoreCard";
import NewMainHeader from "../components/NewMainHeader";
import ReactPaginate from "react-paginate";
import { AiFillLeftCircle, AiFillRightCircle } from "react-icons/ai"; // icons form react-icons
import { IconContext } from "react-icons";
import Pagination from "../components/Pagination/Pagination";
let PageSize = 12;
function Series() {
  const seriesLocation = useLocation()
  // const { id } = seriesLocation.state;
  const { slug } = useParams();
  const i18n = useTranslate();
  const { t } = i18n;
  const language = localStorage.getItem("rcml-lang");
  const [mainPost, setMainPost] = useState([]);
  const [subPosts, setSubPosts] = useState([]);
  const [resultsMatches, setResultsMatches] = useState([]);
  const [upcomingMatches, setUpcomingMatches] = useState([]);
  const [seriesName, setSeriesName] = useState("");
  const [totalPost, setTotalPost] = useState(0);
  const [format, setFormat] = useState("");
  const location = useLocation();
  sessionStorage.setItem('beforeLogin', location.pathname);
  const [resultScorecardFullList, setResultScorecardFullList] = useState(false);
  const [fixturesScorecardFullList, setFixturesScorecardFullList] = useState(false);
  const [points_table, setPointaTableDisplay] = useState(0);

  useEffect(() => {
    var data = { id: slug,language:language };
    getSeriesWiseData(data).then((response) => {

      setSubPosts(response.posts.news);
      setTotalPost(response.posts.news.length)
      setSeriesName(response.posts.series.name);
      setUpcomingMatches(response.matches.upcoming);
      setResultsMatches(response.matches.results);
      setFormat(response.posts.series.short_code);
      setPointaTableDisplay(response.posts.series.points_table);

    });
  }, [slug]);

  const [currentPage, setCurrentPage] = useState(1);

  const currentNewsData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return subPosts.slice(firstPageIndex, lastPageIndex);
  }, [currentPage,subPosts]);


  function stripHTML(myString) {
    let el = document.createElement("div");
    el.innerHTML = myString;
    return el.textContent || el.innerText || "";
  }

  const truncate = (input) =>
    input?.length > 150 ? `${input.substring(0, 120)}...` : input;

  const truncateScoreCardTitle = (input) =>
    input?.length > 18 ? `${input.substring(0, 15)}...` : input;

  const getNumberText = (number) => {
    const lastDigit = number % 10;
    const suffix =
      lastDigit === 1 && number !== 11
        ? 'st'
        : lastDigit === 2 && number !== 12
          ? 'nd'
          : lastDigit === 3 && number !== 13
            ? 'rd'
            : 'th';

    return `${suffix}`;
  };


  const renderUpcomingMatches = upcomingMatches.map((matchItem, ind) => {
    if (ind <= 7) {
      return <SeriesUpcomingScoreCard dataList={matchItem} />
     }
});

  const renderResultsMatches = resultsMatches.map((item, index) => {
    if (index <= 8) {
      return <SeriesScoreCard dataList={item} pageType={'series'} />
    }

  });
  return (
    <>
      <HelmetProvider>
        <div id="main" className="header-big">
          <Helmet>
            <title> {seriesName} |  {t('ln_sports')}</title>
          </Helmet>
          <NewMainHeader />

          <div className="container main-wrapper">
            <div className="main-content mag-content clearfix">
              <div className="row main-body" data-stickyparent>
                <div className="col-md-8">
                  <section className="admag-block">
                    <div className="row">

                      <div className="col-md-12">
                        <h3 className="block-title">
                          <span>Series: {seriesName}</span>
                        </h3>
                        <section className="admag-block">

                          {totalPost == 0 ? (<><h4 className="text-muted">{t('no_post')} </h4></>) : (<>

                            <div className="row">
                              {currentNewsData.map((item) => {
                                return (
                                  <>
                                    <div className="col-md-4 col-sm-6 col-xs-12" >
                                      <article className="news-block small-block"  >
                                        <Link to={`/series/news/${item.id}`} className="overlay-link">
                                          <figure className="image-overlay">
                                            <img src={`${postImageUrl}${item.main_image_path}`} alt="" />
                                          </figure>
                                        </Link>

                                        <header className="news-details" style={{ background: 'white' }}>
                                          <h3 className="news-title heading-text-line-4-warp">
                                            <Link to={`/series/news/${item.id}`} className="news-title-new">
                                              {truncate(item.title)}
                                            </Link>
                                          </h3>
                                          <p className="simple-share">
                                            <Time time={new Date(item.created_at)} />
                                          </p>
                                        </header>
                                      </article>
                                    </div>
                                  </>
                                )
                              })}
                              <div className="col-md-12 col-sm-12 col-xs-12" >
                             
                                <Pagination
                                  className="pagination-bar"
                                  currentPage={currentPage}
                                  totalCount={subPosts.length}
                                  pageSize={PageSize}
                                  onPageChange={page => setCurrentPage(page)}
                                />
                            
                              </div>
                            </div>

                          </>)}


                        </section>
                      </div>
                    </div>
                  </section>
                </div>

                <div className="col-md-4" data-stickycolumn>
                  <div className="widget tabwidget">
                    <ul className="nav nav-tabs" role="tablist" id="widget-tab">
                      <li role="presentation" className="active">
                        <a
                          href="#tab-popular"
                          aria-controls="tab-popular"
                          role="tab"
                          data-toggle="tab"
                        >
                          {t('series_page_results')}
                        </a>
                      </li>
                      <li role="presentation">
                        <a
                          href="#tab-recent"
                          aria-controls="tab-recent"
                          role="tab"
                          data-toggle="tab"
                        >
                          {t('series_page_fixtures')}
                        </a>
                      </li>
                      {points_table == 1 ? (<>
                        <li role="presentation">
                          <Link to={`/points-table/${slug}`} state={{ id: slug }}><span>Points Table</span></Link>
                        </li>
                      </>) : (<></>)}
                    </ul>

                    <div className="tab-content">
                      <div role="tabpanel" className="tab-pane active" id="tab-popular">
                        {resultsMatches.length == 0 ? (<><h4 className="text-muted">{t('no_matches')} </h4></>) : (<>
                          {renderResultsMatches}
                          {resultScorecardFullList
                            ? resultsMatches.map((dataRow, index) => {
                              if (index > 8) {
                                return <SeriesScoreCard dataList={dataRow} pageType={'series'} />;
                              }
                            })
                            : null}
                            {resultsMatches.length > 7 ? (<>
                              <div className="btn-full-list" onClick={() => setResultScorecardFullList(!resultScorecardFullList)}>{resultScorecardFullList ? "View Less" : "View All Results"}</div>
                            </>):(<></>)}
                         

                        </>)}

                      </div>
                      <div role="tabpanel" className="tab-pane" id="tab-recent">
                        {upcomingMatches.length == 0 ? (<><h4 className="text-muted">{t('no_matches')} </h4></>) : (<>
                          {renderUpcomingMatches}
                          {fixturesScorecardFullList
                            ? upcomingMatches.map((dataRow, index) => {
                              if (index > 7) {
                                return <SeriesUpcomingScoreCard dataList={dataRow} />;
                              }
                            })
                            : null}
                             {upcomingMatches.length > 6 ? (<>
                                <div className="btn-full-list" onClick={() => setFixturesScorecardFullList(!fixturesScorecardFullList)}>{fixturesScorecardFullList ? "View Less" : "View All Fixtures"}</div>
                          </>):(<></>)}
                        </>)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>

      </HelmetProvider>
    </>
  );
}
export default Series;
