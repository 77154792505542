import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { getHomeSection6Posts } from "../services/PostAPIServices";
import { getAllAdvertisements } from "../services/AdvertisementAPIServices";
import { postImageUrl } from "../UrlPaths";
import { Time } from "./Time";
import { useTranslate } from '../translate';
import { SocialMedia } from "./SocialMedia";
function HomeSection6() {
  const i18n = useTranslate();
  const { t } = i18n;
  const language = localStorage.getItem("rcml-lang");
  const [mainPost, setMainPost] = useState([]);
  const [subPosts, setSubPosts] = useState([]);
  const [recentPosts, setRecentosts] = useState([]);
  const [moreNewsAd, setMoreNewsAd] = useState("");
  const [moreNewsAdLink, setMoreNewsAdLink] = useState("");
  const [recentNewsAd, setRecentNewsAd] = useState("");
  const [recentNewsAdLink, setRecentNewsAdLink] = useState("");

  useEffect(() => {
    const data = {
      language
    };
    getHomeSection6Posts(data).then((response) => {
      setMainPost(response.mainPosts);
      setRecentosts(response.recentPosts);
      setSubPosts(response.subPosts);
    });

  getAllAdvertisements(data).then((response) => {
    
    response.records.forEach(element => {
      if(element.placement_tag == "more_news_section_ad" && (element.img_url != "" && element.img_url != null)){
        setMoreNewsAd(postImageUrl+element.img_url);
        setMoreNewsAdLink(element.link_url)
      }
      if(element.placement_tag == "recent_section_ad" && (element.img_url != "" && element.img_url != null)){
        setRecentNewsAd(postImageUrl+element.img_url);
        setRecentNewsAdLink(element.link_url)
      }
   
    });
  });
  }, []);

  function stripHTML(myString) {
    let el = document.createElement("div");
    el.innerHTML = myString;
    return el.textContent || el.innerText || "";
 }

 const truncate = (input) =>
 input?.length > 60 ? `${input.substring(0, 55)}...` : input;

  const renderMainPost = mainPost.map((item, index) => (
    <article className="news-block big-block">
      <Link to={`/post/${item.post_id}`} className="overlay-link">
        <figure className="image-overlay">
          <img src={`${postImageUrl}${item.main_image_path}`} alt="" />
        </figure>
      </Link>
      <Link to={`/category/${item.slug}`} className="category">
        {item.category_name}
      </Link>
      <header className="news-details">
        <h3 className="news-title">
          <Link to={`/post/${item.post_id}`}>{item.title}</Link>
        </h3>
        <p>
        {stripHTML(item.short_content)}...
        <br/>
        <Time time={new Date(item.created_at)}/>
        </p>
        {/* <p className="simple-share">
        <Time time={new Date(item.created_at)}/>
        </p> */}
      </header>
    </article>
  ));

  const renderSubPosts = subPosts.map((item, index) => (
    index == 3 ? (<>
    {moreNewsAd != "" ? (    <center>
      <div class="widget" >
      <a href={moreNewsAd} target="_blank">
        <img src={moreNewsAdLink} alt=""/>
      </a>
    </div>
      </center>):(<></>)}
    <article className="simple-post simple-big clearfix">
      <div className="simple-thumb">
        <Link to={`/post/${item.post_id}`}>
          
          <img src={`${postImageUrl}${item.main_image_path}`} alt="" />
        </Link>
      </div>
      <header>
        <p className="simple-share">
         <b> <Link to={`/category/${item.slug}`}>
            {item.category_name}
          </Link></b>
          <Time time={new Date(item.created_at)}/>
        </p>

        <h5>
         <Link to={`/post/${item.post_id}`}  className="e-blogs mobile-text-warp-3-line">{truncate(item.title)}</Link>
        </h5>
        </header>
        <p className="excerpt text-warp-3-line">
        {stripHTML(item.short_content)}...
        </p>
     
    </article></>):(<>    
      <article className="simple-post simple-big clearfix">
      <div className="simple-thumb">
        <Link to={`/post/${item.post_id}`}>
          
          <img src={`${postImageUrl}${item.main_image_path}`} alt="" />
        </Link>
      </div>
      <header>
        <p className="simple-share">
         <b> <Link to={`/category/${item.slug}`}>
            {item.category_name} {" "}
          </Link></b>
          <Time time={new Date(item.created_at)}/>
        </p>

        <h5>
         <Link to={`/post/${item.post_id}`}  className="e-blogs mobile-text-warp-3-line">{truncate(item.title)}</Link>
        </h5>
        </header>
        <p className="excerpt text-warp-3-line">
        {stripHTML(item.short_content)}...
        </p>
     
    </article></>)

  ));

  const renderRecentPosts = recentPosts.map((item, index) => (
    index == 2 ? (<>
    {recentNewsAd != "" ?(    <center>
      <div class="widget">
      <a href={recentNewsAd} target="_blank">
        <img src={recentNewsAdLink} alt=""/>
      </a>
    </div>
      </center>):(<></>)}

      <div className="widget-post clearfix">
    <div className="author-thumb">
      <Link to={`/post/${item.post_id}`}>
        <img src={`${postImageUrl}${item.main_image_path}`} alt="" />
      </Link>
    </div>
    <div>
      <h3>
        <Link className="mobile-text-warp-4-line" to={`/post/${item.post_id}`}>{item.title}</Link>
      </h3>
      <p className="simple-share">
      <Time time={new Date(item.created_at)}/>
      </p>
    </div>
  </div></>):(<><div className="widget-post clearfix">
    <div className="author-thumb">
      <Link to={`/post/${item.post_id}`}>
        <img src={`${postImageUrl}${item.main_image_path}`} alt="" />
      </Link>
    </div>
    <div>
      <h3>
        <Link className="mobile-text-warp-4-line" to={`/post/${item.post_id}`}>{item.title}</Link>
      </h3>
      <p className="simple-share">
      <Time time={new Date(item.created_at)}/>
      </p>
    </div>
  </div></>)

  ));
  return (
    <div className="row" data-stickyparent>
      <div className="col-md-8">
        <section className="admag-block">
          <h3 className="block-title">
            <span>{t('more_news')}</span>
          </h3>

          {renderMainPost}

          {renderSubPosts}
        </section>

        <div className="load-more">
        <Link to={'posts/news'} class="view-more">
          <button type="button" className="btn btn-lg btn-block">
         {t('view_more_news')}
          </button>
          </Link>
        </div>
      </div>
      <div className="col-md-4" data-stickycolumn>
        <aside className="sidebar">
          <div className="widget social-links col-md-12 col-sm-4 remove-paddings tab-shift-follow-us">
            <h3 className="block-title">
              <span>{t('follow_us')}</span>
            </h3>
            <SocialMedia/>
         
          </div>

          <div className="widget col-md-12 col-sm-8 remove-paddings remove-paddings remove-left-padding">
            <h3 className="block-title">
              <span>{t('recent_posts')}</span>
            </h3>
            <div className="row">
              <div className="col-md-12">{renderRecentPosts}</div>
            </div>
          </div>
        </aside>
      </div>
    </div>
  );
}
export default HomeSection6;
