import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../components/Footer";
import MainHeader from "../components/MainHeader";
import "antd/dist/antd.css";
import { useTranslate } from '../translate';
import {postImageUrl} from "../UrlPaths";
import {Time} from "../components/Time";
import { getSearchResult} from "../services/PostAPIServices";
import NewMainHeader from "../components/NewMainHeader";

function Search() {
  const i18n = useTranslate();
  const { t } = i18n;
  const language = localStorage.getItem("rcml-lang");
  const [subPosts, setSubPosts] = useState([]);
  const [searchKey, setSearchKey] = useState("");
  const [searchTitle, setSearchTitle] = useState("");
  const [searchResult, setSearchResult] = useState("");

  useEffect(() => {

  }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        const data = {
            language,
            searchKey
        };

        getSearchResult(data).then((response) => {

            setSubPosts(response.posts);
            let display = response.posts.length === 0 ? 'No post found':'';
            setSearchTitle("Search results:");
            setSearchResult(display)
        });
    }
    function stripHTML(myString) {
        let el = document.createElement("div");
        el.innerHTML = myString;
        return el.textContent || el.innerText || "";
    }

    const truncate = (input) =>
        input?.length > 60 ? `${input.substring(0, 55)}...` : input;

    const renderSubPosts = subPosts.map((item, index) => (
        <div className="col-md-4">
            <article className="news-block small-block article-main">
                <Link reloadDocument to={`/post/${item.post_id}`} className="overlay-link">
                    <figure className="image-overlay">
                        <img src={`${postImageUrl}${item.main_image_path}`} alt="" />
                    </figure>
                </Link>

                <header className="news-details" style={{background:'white',overflow:"hidden" }}>
                    <Time time={new Date(item.created_at)}  />
                    <h4><b>
                        <Link
                            reloadDocument
                            to={`/post/${item.post_id}`}
                            className="e-blogs"
                        >
                            {truncate(item.title)}
                        </Link>
                    </b> </h4>
                    <p style={{color:'#a9a9a9'}}> {stripHTML(item.short_content)}...</p>

                    {/* <p className="simple-share" >
            <br />
          </p> */}
                </header>
            </article>
        </div>
    ));

  return (
    <>
      <div id="main" className="header-big">
      <Helmet>
      <title>Search</title>
      </Helmet>
          <NewMainHeader />
        <div className="container main-wrapper">
          <div className="main-content mag-content clearfix">
            <div id="registerForm" className="registerForm-wrapper clearfix">
              <div id="respond" className="comment-form clearfix">
                <h3 className="comment-title text-center"> Search </h3>
                  <div className="row">
                      <div className="col-md-2"></div>
                      <div className="col-md-8">
                      <div className="search-div clearfix">
                          <form className="searchwidget-form"
                                onSubmit={(e) => {
                              handleSubmit(e);
                          }}>
                              <div className="input-group">
                                  <input type="text" style={{borderRadius:'30px'}} className="form-control" onChange={(e) => setSearchKey(e.target.value)} value={searchKey} placeholder="Search..." required />
                                  <span className="input-group-btn">
                                    <button className="btn btn-default"  type="submit">
                                        <i class="fa fa-search"></i></button>
                                  </span>
                              </div>
                          </form>
                      </div>
                      </div>
                      <div className="col-md-2"></div>
                      <div className="col-md-12">
                          <h3 className="tag-title">{searchTitle} <span>{searchResult}</span></h3>
                          {renderSubPosts}
                      </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>

      <div
        id="go-top-button"
        className="fa fa-angle-up"
        title="Scroll To Top"
      ></div>
      <div className="mobile-overlay" id="mobile-overlay"></div>
    </>
  );
}
export default Search;
