import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link, useParams ,useLocation} from "react-router-dom";
import { postImageUrl } from "../UrlPaths";

import { getAllActiveEvents,getLiveStreamState } from "../services/EventAPIServices";
import Footer from "../components/Footer";
import MainHeader from "../components/MainHeader";
import SideTabs from "../components/SideTabs";
import { Time } from "../components/Time";
import { useTranslate } from '../translate';
import NewMainHeader from "../components/NewMainHeader";

function Event() {
  const i18n = useTranslate();
  const { t } = i18n;
  const userName = localStorage.getItem("user");
  const [loggedUser, setLoggedUser] = useState(userName);
  const [upcomingEvents, setUpcomingEvents] = useState([]);
  const [passedEvents, setPassedEvents] = useState([]);
  const location = useLocation();
  sessionStorage.setItem('beforeLogin',location.pathname);

  useEffect(() => {
    getAllActiveEvents().then((response) => {
      setUpcomingEvents(response.upcoming_events);
      setPassedEvents(response.passed_events);
    });
    setTimeout(() => {
      setTimeLeft(calculateTimeLeft(new Date()));
    }, 1000);
  }, []);

  const calculateTimeLeft = (today) => {
    // let year = new Date().getFullYear();
    // var date = moment();

    // var currentDate = date.format('D/MM/YYYY');
    const difference = +new Date(today) - +new Date();
    let timeLeft = "";
    console.log("today:"+new Date(today));
    console.log(difference);


    if (difference > 0) {
      if (Math.floor(difference / (1000 * 60 * 60 * 24)) > 0) {
        timeLeft += Math.floor(difference / (1000 * 60 * 60 * 24)) + " Days";
      } else if (Math.floor((difference / (1000 * 60 * 60)) % 24) > 0) {
        timeLeft += Math.floor((difference / (1000 * 60 * 60)) % 24) + " Hours";
      } else {
        timeLeft += Math.floor((difference / 1000 / 60) % 60) + " Minutes";
      }
    }
    console.log("timeLeft :"+ timeLeft);
    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(new Date()));


  function createMarkup(eventContent) {
    return { __html: eventContent };
  }

  const renderUpcomingEvents = upcomingEvents.map((item, index) => {
    var date_time = item.start_date+item.start_time;
    var remainingTime = calculateTimeLeft(item.start_date);

    return (
      <div class="col-md-12 news-text-block" style={{ marginBottom: "10px" }}>
        <h3 style={{ borderBottom: "2px solid #d6d6d6" }}>
          <span>{item.title}</span>
        </h3>

        <article class="widget-post clearfix">
          <div class="simple-thumb" style={{ width: "255px" }}>
            {/* <a href="#"> */}
            <img src={`${postImageUrl}${item.featured_image}`} alt="" />
            {/* </a> */}
          </div>
          <header>
            <Link
              to={`/category/${item.slug}`}
              className="category bgcolor3"
              style={{ position: "inherit", color: "white" }}
            >
              {item.category_name}
            </Link>
            <div
              className="ck-content"
              dangerouslySetInnerHTML={createMarkup(item.description)}
            />
            <p style={{ color: "#a9a9a9" }}>
              <b>Venue</b>: {item.venue} <br />
              <b>Start Date</b>: {item.display_start_date} &nbsp;
              <b>Time</b>: {item.start_time}
              <br />
              <b>End Date</b>: {item.display_end_date}
            </p>

            <p className="simple-share" style={{ marginBottom: "10px" }}>
              <Time time={new Date(item.created_at)} />
            </p>
          </header>

          <div className="col-md-12 text-right">
            {loggedUser == "" || loggedUser == null ? (
              <p style={{ color: "#555555" }}>
              
                <Link to={`/login`}>
                  <button
                    name="submit"
                    type="button"
                    className="btn btn-warning "
                    style={{ borderRadius: "5px" }}
                  >
                    {t('login_to_view_label')}
                  </button>
                </Link>
              </p>
            ) : (
              <>
               
                    {remainingTime != "" ? (
                     
                       <button
                         name="submit"
                         type="submit"
                         disabled={true}
                         className="btn btn-warning"
                         style={{ borderRadius: "5px" }}
                       >
                      <> {remainingTime} Left</>
                      </button>
               
                    ) : (
                                    
                      <Link to={`/event/${item.id}`}>
                      <button                          
                        name="submit"
                         type="submit"
                         className="btn btn-danger"
                         > Let's go</button>
                    </Link>                    
                   )}
                 
            
           </> )}
          </div>
        </article>
      </div>
    );
  });

  const renderPassedEvents = passedEvents.map((item, index) => (
    <div class="col-md-12 news-text-block">
      <h3 style={{ borderBottom: "2px solid #d6d6d6" }}>
        <span>{item.title}</span>
      </h3>

      <article class="widget-post clearfix">
        <div class="simple-thumb" style={{ width: "255px" }}>
          {/* <a href="#"> */}
          <img src={`${postImageUrl}${item.featured_image}`} alt="" />
          {/* </a> */}
        </div>
        <header>
          <div
            className="ck-content"
            dangerouslySetInnerHTML={createMarkup(item.description)}
          />
          <p style={{ color: "#a9a9a9" }}>
            <b>Venue</b>: {item.venue} <br />
            <b>Start Date</b>: {item.start_date} &nbsp;
            <b>Time</b>: {item.start_time}
            <br />
            <b>End Date</b>: {item.end_date}
          </p>
          <p class="simple-share">
            <Time time={new Date(item.created_at)} />
          </p>
        </header>
      </article>
    </div>
  ));
  return (
    <>
      <div id="main" class="header-big">
      <Helmet>
      <title>{t("events_title")} | LN Sports Network</title>
      </Helmet>
        <NewMainHeader />
        <div class="container main-wrapper">
          <div class="main-content mag-content clearfix">
            <div class="row  main-body">
              <div class="col-md-8">
                <article class="post-wrapper clearfix">
                  <header class="post-header">
                    <h2 class="post-title">{t("events_title")}</h2>
                  </header>
                  <div class="post-content clearfix">
                    <div
                      class="bs-example bs-example-tabs"
                      role="tabpanel"
                      data-example-id="togglable-tabs"
                    >
                      <ul id="myTab" class="nav nav-tabs" role="tablist">
                        <li role="presentation" class="active">
                          <a
                            href="#tab1"
                            id="tabnav1"
                            role="tab"
                            data-toggle="tab"
                            aria-controls="tab1"
                            aria-expanded="true"
                          >
                             {t('upcoming_event_label')}
                          </a>
                        </li>
                        <li role="presentation">
                          <a
                            href="#tab2"
                            role="tab"
                            id="tabnav2"
                            data-toggle="tab"
                            aria-controls="tab2"
                          >
                            {t('passed_event_label')}
                          </a>
                        </li>
                      </ul>
                      <div id="myTabContent" class="tab-content">
                        <div
                          role="tabpanel"
                          class="tab-pane fade in active"
                          id="tab1"
                          aria-labelledby="tab1"
                        >
                          <section class="admag-block">
                            <div class="row">
                              {upcomingEvents != "" ? (
                                <>{renderUpcomingEvents}</>
                              ) : (
                                <div class="col-md-12 news-text-block">
                                  <h3>
                                    <span style={{fontSize:'15px'}}>{t('no_any_event_label')}</span>
                                  </h3>
                                </div>
                              )}
                            </div>
                          </section>
                        </div>
                        <div
                          role="tabpanel"
                          class="tab-pane fade"
                          id="tab2"
                          aria-labelledby="tab2"
                        >
                          <section class="admag-block">
                            <div class="row">
                              {passedEvents != "" ? (
                                <>{renderPassedEvents}</>
                              ) : (
                                <div class="col-md-12 news-text-block">
                                  <h3>
                                    <span style={{fontSize:'15px'}}>{t('no_any_event_label')}</span>
                                  </h3>
                                </div>
                              )}
                            </div>
                          </section>
                        </div>
                      </div>
                    </div>
                  </div>
                </article>
              </div>
              <div className="col-md-4" data-stickycolumn>
                <SideTabs />
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>

      <div
        id="go-top-button"
        class="fa fa-angle-up"
        title="Scroll To Top"
      ></div>
      <div class="mobile-overlay" id="mobile-overlay"></div>
    </>
  );
}
export default Event;
