import React, { useState, useEffect, useMemo } from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Link, useParams, useLocation } from "react-router-dom";
import { postImageUrl } from "../UrlPaths";

import Footer from "../components/Footer";
import { Time } from "../components/Time";
import { useTranslate } from '../translate';
import {getPostTypeRelatedPosts} from "../services/PostAPIServices";

import NewMainHeader from "../components/NewMainHeader";
import Pagination from "../components/Pagination/Pagination";

function PostType() {
  const { slug } = useParams();
  const i18n = useTranslate();
  const { t } = i18n;
  const language = localStorage.getItem("rcml-lang");

  const [postList, setPostList] = useState([]);
  const [typeName, setTypeName] = useState("");
  const location = useLocation();
  sessionStorage.setItem('beforeLogin', location.pathname);
  let PageSize = 12;


  useEffect(() => {
    var data = { slug: slug,language:language };
    getPostTypeRelatedPosts(data).then((response) => {
      setPostList(response.posts);
      setTypeName(response.type_name)
    });
  }, [slug]);

  const [currentPage, setCurrentPage] = useState(1);

  const currentNewsData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return postList.slice(firstPageIndex, lastPageIndex);
  }, [currentPage,postList]);

  const truncate = (input) =>
    input?.length > 150 ? `${input.substring(0, 120)}...` : input;


  return (
    <>
      <HelmetProvider>
        <div id="main" className="header-big">
          <Helmet>
            <title> {slug == 'trending' ? t('more_trending') : t('view_more_news')} |  {t('ln_sports')}</title>
          </Helmet>
          <NewMainHeader />

          <div className="container main-wrapper">
            <div className="main-content mag-content clearfix">
              <div className="row main-body" data-stickyparent>
                <div className="col-md-12">
                  <section className="admag-block">
                    <div className="row">

                      <div className="col-md-12">
                        <h3 className="block-title">
                          <span>{slug == 'trending' ? t('more_trending') :  t('view_more_news')} </span>
                        </h3>
                        <section className="admag-block">

                          {postList.length == 0 ? (<><h4 className="text-muted">{t('no_post')} </h4></>) : (<>

                            <div className="row">
                              {currentNewsData.map((item) => {
                                return (
                                  <>
                                    <div className="col-md-4 col-sm-6 col-xs-12 height-adjust">
                                      <article className="news-block small-block"  >
                                        <Link to={`/post/${item.id}`} className="overlay-link">
                                          <figure className="image-overlay">
                                            <img src={`${postImageUrl}${item.main_image_path}`} alt="" />
                                          </figure>
                                        </Link>

                                        <header className="news-details" style={{ background: 'white' }}>
                                          <h3 className="news-title heading-text-line-4-warp">
                                            <Link to={`/post/${item.id}`} className="news-title-new">
                                              {truncate(item.title)}
                                            </Link>
                                          </h3>
                                          <p className="simple-share">
                                            <Time time={new Date(item.created_at)} />
                                          </p>
                                        </header>
                                      </article>
                                    </div>
                                  </>
                                )
                              })}
                              <div className="col-md-12 col-sm-12 col-xs-12" >
                             
                                <Pagination
                                  className="pagination-bar"
                                  currentPage={currentPage}
                                  totalCount={postList.length}
                                  pageSize={PageSize}
                                  onPageChange={page => setCurrentPage(page)}
                                />
                            
                              </div>
                            </div>

                          </>)}


                        </section>
                      </div>
                    </div>
                  </section>
                </div>

    
              </div>
            </div>
          </div>
          <Footer />
        </div>

      </HelmetProvider>
    </>
  );
}
export default PostType;
