import React, {useEffect, useState} from "react";
import {Link, NavLink, useNavigate} from "react-router-dom";
import {IoClose, IoMenu} from "react-icons/io5";
import "./Navbar.css";
import {useTranslate} from "../translate";
import Menu from "./NavigationMenu";
import Swal from "sweetalert2";
import {logoutAPI} from "../services/AuthAPIServices";
import Search from "./Search";

const NewNavbar = ({postCategory, showFlex}) => {
    const i18n = useTranslate();
    const {t} = i18n;
    let navigate2 = useNavigate();

    const language = localStorage.getItem("rcml-lang");
    const [showMenu, setShowMenu] = useState(false);
    const userName = localStorage.getItem("user");
    const [loggedUser, setLoggedUser] = useState(userName);
    const [hashTag, setHashTag] = useState(localStorage.getItem("hashTagStorage"));
    const beforelogout = sessionStorage.getItem('beforeLogin');
    const [isMobile, setIsMobile] = useState("");

    useEffect(() => {
        setIsMobile(showFlex)
    }, [showFlex]);
    const toggleMenu = () => {
        setShowMenu(!showMenu);
        document.body.classList.toggle('no-scroll', !showMenu);
    };

    const closeMenuOnMobile = () => {
        if (window.innerWidth <= 1024) {
            setShowMenu(false);
            document.body.classList.toggle('no-scroll', false);
        }
    };

    const logoutEvent = (e) => {
        Swal.fire({
            title: "Are you sure logout?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes",
        }).then((result) => {
            if (result.isConfirmed) {
                logoutAPI().then((response) => {
                    if (response.status_code == "1") {
                        localStorage.removeItem("access_token");
                        localStorage.setItem("authenticated", false);
                        localStorage.removeItem("user");
                        localStorage.removeItem("img_path");
                        localStorage.removeItem("user_id");
                        navigate2(beforelogout == null ? "/" : beforelogout);
                        navigate2(0);
                    }
                });
            }
        });
    };
    return (
        <div className={`${showFlex ? "header-mobile" : "header"}`} style={{zIndex: `${showMenu ? "auto" : "1"}`}}>
            <div className="">
                {/*<div className="mag-content">*/}
                {/*        <div className="col-md-12">*/}
                            <nav className="navbar mega-menu">
                                <div
                                    className={`nav__menu ${showMenu && isMobile ? "show-menu" : isMobile ? 'hide':""}`}

                                >
                                    <ul className="nav__list">
                                        <Menu postCategory={postCategory} onclick={closeMenuOnMobile}/>
                                        <li className="nav__item match-btn">
                                            <NavLink reloadDocument role="button" to={`/##`}
                                                     className="nav__link dropdown"
                                                     onClick={closeMenuOnMobile}>
                                                Match Predictor
                                            </NavLink>
                                        </li>
                                        <li className="nav__item shop-btn">
                                            <NavLink reloadDocument role="button" to={`/##`}
                                                     className="dropdown nav__link"
                                                     onClick={closeMenuOnMobile}>
                                                Shop
                                            </NavLink>
                                        </li>
                                        <li className="nav__item e-paper-btn">
                                            <NavLink reloadDocument role="button" to={`/##`}
                                                     className="dropdown nav__link"
                                                     onClick={closeMenuOnMobile}>
                                                Guess & Win
                                            </NavLink>
                                        </li>
                                        <li className="nav__item new-search-btn">
                                            <Search/>
                                        </li>
                                    </ul>

                                    <div className="nav__close" id="nav-close" onClick={toggleMenu}>
                                        <IoClose style={{width: '30px', height: '30px'}}/>
                                    </div>
                                </div>

                                <div className="nav__toggle" id="nav-toggle">
                                    <ul>
                                        <li style={{marginTop:'auto',marginLeft: '10px',marginRight:'10px'}}>
                                            <IoMenu size="30px" onClick={toggleMenu} />
                                        </li>
                                            {hashTag == '#mobile' ? (<>
                                            </>) : (<>
                                                {loggedUser == "" || loggedUser == null ? (<>
                                                    {language == 'tamil' ? (
                                                        <>
                                                            <li> <Link to={`/login`}>
                                                                <button
                                                                    className="new-login-btn-en-mobile">{t('login')}</button>
                                                            </Link></li>
                                                            <li>  <Link to={`/sign-up`}>
                                                                <button
                                                                    className="sign-login-btn-tm">{t('sign_up')}</button>
                                                            </Link></li>


                                                        </>
                                                    ) : (<>
                                                                <li> <Link to={`/login`}>
                                                                    <button
                                                                        className="new-login-btn-en-mobile">{t('login')}</button>
                                                                </Link></li>
                                                                <li><Link to={`/sign-up`}>
                                                                    <button
                                                                        className="sign-login-btn-en-mobile">{t('sign_up')}</button>
                                                                </Link></li>
                                                            </>
                                                    )}
                                                </>) : (
                                                    <>
                                                        <span className="header-username">Hello, {loggedUser} </span>
                                                        <span>
                                                            <button
                                                                className="header-logout-btn"
                                                                type="button"
                                                                onClick={(e) => {
                                                                    logoutEvent();
                                                                }}
                                                            >
                                                          <i className="fa fa-power-off"></i>
                                                        </button>
                                                      </span>
                                                    </>
                                                )}

                                            </>)}


                                    </ul>
                                </div>
                            </nav>
                {/*        </div>*/}
                {/*</div>*/}
            </div>
        </div>
    );
};

export default NewNavbar;
