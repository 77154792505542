import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { getHomeSection4Posts } from "../services/PostAPIServices";
import { postImageUrl } from "../UrlPaths";
import { Time } from "./Time";
import { useTranslate } from '../translate';

function HomeSection4() {
  const i18n = useTranslate();
  const { t } = i18n;
  const language = localStorage.getItem("rcml-lang");
  const [mainPost, setMainPost] = useState([]);

  useEffect(() => {
    const data = {
      language
    };
    getHomeSection4Posts(data).then((response) => {
      setMainPost(response.posts);
    });
  }, []);

  const renderPosts = mainPost.map((item, index) => (
    <div className="col-md-4 col-sm-6 justify-content-center">
      <article className="featured-small box-news">
      <Link to={`/post/${item.post_id}`}>
          <img src={`${postImageUrl}${item.main_image_path}`}  alt="" />
        </Link>
        <header className="featured-header">
        <Link to={`/category/${item.slug}`} className="category bgcolor2">
          {item.category_name}
        </Link>
     
          <h2>
          <Link to={`/post/${item.post_id}`}>{item.title}</Link>
          </h2>
          <p className="simple-share">
          <Time time={new Date(item.created_at)}/>
          </p>
        </header>
      </article>
    </div>
  ));

  return (
    <section className="admag-block">
      <div className="row">
        <div className="col-md-12">
          <h3 className="block-title">
            <span>{t('business')}</span>
          </h3>
        </div>
      </div>

      <div className="row justify-content-center">{renderPosts}</div>
    </section>
  );
}
export default HomeSection4;
