import React, {useState} from 'react';
import {useData} from "../timezone";
import ReactFlagsSelect from "react-flags-select";

function FlagSwitcher() {

    const  flag  = localStorage.getItem("flag");
    const [selected, setSelected] = useState(flag);
    const { sharedData, updateData } = useData();
    const countries = [
        { value: 'GB', label: 'UK', timezone:'GMT' },
        { value: 'LK', label: 'Sri Lanka',timezone:'Asia/colombo' },
        { value: 'IN', label: 'India', timezone:'Asia/kolkata'},
        { value: 'CA', label: 'Canada', timezone:'America/Toronto' },
        { value: 'AE', label: 'Dubai', timezone:'Asia/Dubai' },
    ]

  const onChangeFlag = (selected_flag) => {
      setSelected(selected_flag)
        let tz= ""
      countries.map(data => {
          if(data.value === selected_flag){
              tz = data.timezone
          }
      });

      localStorage.setItem("flag",selected_flag);
      localStorage.setItem("tz",tz);

      updateData(tz);
  };

  return (
          <ReactFlagsSelect
              className={"flag-selection"}
              countries={["GB", "LK", "IN", "CA", "AE"]}
              customLabels={{ GB: "GB", LK: "LK", IN: "IN", CA: "CA", AE: "AE" }}
              selected={selected}
              showOptionLabel={true}
              optionsSize={11}
              onSelect={onChangeFlag}
              fullWidth={false}
              selectedSize={11}
              alignOptionsToRight={true}
              selectButtonClassName="menu-flags-button"
          />

  );
}

export default FlagSwitcher;